import React, {useEffect, useState} from 'react';
import {Modal, Button, notification} from 'antd';
import {FormItem, Input} from 'formik-antd';
import {IdcardOutlined, UserOutlined} from '@ant-design/icons';
import {Trans, useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {useMediaQuery} from '../../hooks/useMediaQuery';
import {Paragraph} from '../Paragraph';
import {Extra} from './Shared';
import {AnchorLink} from '../AnchorLink';
import {Formik} from 'formik';
import * as yup from 'yup';
import {config} from '../../stage.config';

interface ICountryModalProps {
  onCheckingCountryChange: (checkingCountry: boolean) => void;
}

interface IFormValues {
  firstName: string;
  email: string;
}

const LegalInfo = styled(Paragraph)`
  margin-bottom: 0;
  font-size: 12px;
`;

const CheckCountryModal: React.FC<ICountryModalProps> = ({onCheckingCountryChange}) => {
  const {t} = useTranslation();
  const {isMobile} = useMediaQuery();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [countryCode, setCountryCode] = useState('');

  useEffect(() => {
    fetch(config.checkCountriesEndpoint)
      .then((response) => response.json())
      .then((data) => {
        if (!data.isSupported) {
          setIsModalVisible(true);
          setCountryCode(data.currentCountryCode);
        }
        onCheckingCountryChange(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = (values: IFormValues) => {
    fetch(config.pinpointApi + `user/subscribe`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...values,
        countryCode,
        topics: ['UnsupportedCountries']
      })
    })
      .then((result) => result.json())
      .then((result) => {
        notification.success({
          message: t('Success'),
          description: t('You have been subscribed successfully')
        });
      })
      .catch((error) => {
        notification.error({
          message: t('Error'),
          description: t('Something went wrong, please try again later')
        });
      })
      .finally(() => {
        setIsModalVisible(false);
      });
  };

  const validationSchema = yup.object().shape({
    firstName: yup.string().required(t('First name is required')),
    email: yup.string().email(t('Invalid email')).required(t('Email is required'))
  });

  const initialValues = {
    firstName: '',
    email: ''
  };

  return (
    <Modal
      title={false}
      open={isModalVisible}
      footer={null}
      width={600}
      closable={false}
      bodyStyle={{padding: isMobile ? 20 : 40}}
      centered={true}>
      <Trans parent="h2">Country is not supported</Trans>
      <Trans parent="p">
        Unfortunately, MONEI is not yet available in your country. Please provide your email if you
        would like to be notified when registration opens.
      </Trans>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({submitForm, isSubmitting, isValid}) => (
          <>
            <FormItem name="firstName">
              <Input
                autoFocus
                name="firstName"
                type="name"
                autoComplete="firstName"
                size="large"
                prefix={<UserOutlined style={{color: 'rgba(0, 0, 0, 0.25)'}} />}
                placeholder={t('First name')}
              />
            </FormItem>
            <FormItem name="email">
              <Input
                autoFocus
                name="email"
                type="email"
                autoComplete="email"
                size="large"
                prefix={<IdcardOutlined style={{color: 'rgba(0, 0, 0, 0.25)'}} />}
                placeholder={t('Email')}
              />
            </FormItem>
            <Button
              block
              size="large"
              type="primary"
              onClick={submitForm}
              disabled={!isValid}
              loading={isSubmitting}>
              <Trans>Subscribe</Trans>
            </Button>
          </>
        )}
      </Formik>
      <Extra>
        <Paragraph>
          <Trans>
            I have a business in Spain or Andorra.{' '}
            <AnchorLink onClick={handleCancel}>Proceed with registration</AnchorLink>
          </Trans>
        </Paragraph>
        <LegalInfo type="secondary">
          <Trans>
            By providing your contact information (name and email), you will receive details about
            MONEI’s registration process, enabled jurisdictions, payment methods, and contract
            information if applicable. You may also receive electronic communications per our
            Privacy Policy and your consents. MONEI Digital Payments S.L., NIF B02660926, will
            process your data to handle your request for information, access, or registration, and
            manage commercial, contractual, or pre-contractual relationships. MONEI’s necessary
            collaborators may access your data. You have the right to access, rectify, oppose,
            limit, and delete your data, as detailed in our{' '}
            <AnchorLink href={config.privacyPolicyUrl}>Privacy Policy</AnchorLink>.
          </Trans>
        </LegalInfo>
      </Extra>
    </Modal>
  );
};

export default CheckCountryModal;
