import {Button, Modal} from 'antd';
import {ButtonProps} from 'antd/lib/button';
import {useFormikContext} from 'formik';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useRouteMatch} from 'react-router-dom';

interface IProps extends ButtonProps {
  backPath?: string;
}

const {confirm} = Modal;

export const CancelButton: React.FC<IProps> = ({backPath, children = 'Cancel', ...props}) => {
  const match = useRouteMatch();
  const history = useHistory();
  const formik = useFormikContext();
  const {t} = useTranslation();
  const path = match.url.split('/');
  path.pop();

  const goBack = () => {
    return backPath ? history.push(backPath) : history.push(path.join('/'));
  };

  const onCancel = (dirty: boolean) => {
    if (!dirty) return goBack();
    confirm({
      title: t('Discard changes?'),
      content: t('Are you sure you want to discard your changes?'),
      okText: t('Discard'),
      okType: 'danger',
      onOk: goBack,
      onCancel() {}
    });
  };

  return (
    <Button onClick={() => onCancel(Boolean(formik?.dirty))} {...props}>
      {children}
    </Button>
  );
};
