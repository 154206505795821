import {IUser} from 'types';

export const getAccessLevels = (currentUser: IUser) => {
  const session = currentUser.getSignInUserSession()!;
  const {payload} = session.getIdToken();
  return payload['cognito:groups'] || [];
};

export const getUserAttributes = (currentUser: IUser) => ({
  userEmail: currentUser.attributes.email,
  userPhone: currentUser.attributes.phone_number,
  userId: currentUser.attributes.sub,
  accountId: currentUser.attributes['custom:account_id'],
  promoCode: currentUser.attributes['custom:promo_code'],
  privacyPolicyUrl: currentUser.attributes['custom:pp_doc'],
  privacyPolicyAcceptedAt: currentUser.attributes['custom:pp_accepted_at'],
  termsOfServiceUrl: currentUser.attributes['custom:tos_doc'],
  termsOfServiceAcceptedAt: currentUser.attributes['custom:tos_accepted_at'],
  preContractUrl: currentUser.attributes['custom:pc_doc'],
  preContractAcceptedAt: currentUser.attributes['custom:pc_accepted_at'],
  biometricPolicyUrl: currentUser.attributes['custom:bp_doc'],
  biometricPolicyAcceptedAt: currentUser.attributes['custom:bp_accepted_at'],
  storeId: currentUser.attributes['custom:store_id'],
  accessLevels: getAccessLevels(currentUser)
});

export const normalizeErrorMessage = (message: string) => {
  const match = message.match(/(\w+) failed with error ([\w ]+)/);
  if (match) {
    return match[2];
  }
  return message;
};
