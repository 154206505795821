import {colors} from 'lib/colors';
import {lighten} from 'polished';
import {createGlobalStyle} from 'styled-components';

const nProgressColor = lighten(0.3, colors.primary);

export const GlobalStyle = createGlobalStyle`
  ul {
    margin: 0;
    padding: 0;
  }

  .ant-table-placeholder {
    min-height: 100px;
  }

  .awsappsync {
    height: 100%;
  }

  #nprogress {
    pointer-events: none;

    .bar {
      background: ${nProgressColor};
      position: fixed;
      z-index: 2048;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 2px;
    }

    .peg {
      display: block;
      position: absolute;
      right: 0;
      width: 100px;
      height: 100%;
      box-shadow: 0 0 10px ${nProgressColor}, 0 0 5px ${nProgressColor};
      opacity: 1;
      transform: rotate(3deg) translate(0, -4px);
    }

    .spinner {
      display: block;
      position: fixed;
      z-index: 1031;
      top: 15px;
      right: 15px;
    }

    .spinner-icon {
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      border: solid 2px transparent;
      border-top-color: ${nProgressColor};
      border-left-color: ${nProgressColor};
      border-radius: 50%;
      animation: nprogress-spinner 400ms linear infinite;
    }
  }

  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;

    #nprogress {
      .bar,
      .spinner {
        position: absolute;
      }
    }
  }

  @keyframes nprogress-spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .ant-typography {
    & ol li, & ul li {
      margin-bottom: 1em !important;
    }
  }

  .ant-result-content .ant-card, .ant-layout-header {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.03);
  }

  .ant-card-extra {
    padding: 0;
  }

  .clickable {
    cursor: pointer;
  }

  .minimized-cell {
    width: 1px;
    white-space: nowrap;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .ant-form-item-label > label {
    display: flex;
  }

  .ant-table-row.disabled > td {
    background: #f7fafc;
  }

  .ant-input-group.ant-input-group-compact > * {
    display: inline-block;
  }

  .ant-btn > .ant-btn-loading-icon .anticon {
    padding-right: 8px !important;
  }

  .ant-form label:not(.ant-radio-wrapper,.ant-checkbox-wrapper) {
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  .ant-btn.disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.5);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }

  .ant-select-selector .ant-select-selection-item {
    transition: none !important;
  }

  .ant-modal-wrap, .ant-modal-mask {
    z-index: 15;
  }


  .ant-modal-wrap.mobile-fullscreen {
    @media (max-width: 991px) {
      .ant-modal {
        height: 100%;
        overflow: hidden;
        top: 0;
        margin: 0;
        max-width: 100%;
      }

      .ant-modal-content {
        height: 100%;
        overflow: scroll;
        box-shadow: none;
        display: flex;
        flex-direction: column;
      }

      .ant-modal-body {
        border-radius: 0 !important;
      }
    }
  }

  .ant-form-large label {
    font-size: 16px;
  }
  
  .ant-result-content {
    border-radius: 2px;
    border: 1px solid #e8e8e8;
  }
  

  #CookiebotWidget {
    z-index: 9 !important;
  }
  
  #CookiebotWidgetUnderlay {
    z-index: 8 !important;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
  
  #launcher {
    scale: 0.75 !important;
    z-index: 10 !important;
  }
`;
