import {Typography} from 'antd';
import React from 'react';

const {Text} = Typography;

type Props = {email?: string | null};

export const EmailLink: React.FC<Props> = ({email, children}) => {
  if (!email) return <Text type="secondary">No email provided</Text>;
  return <a href={`mailto:${email}`}>{children || email}</a>;
};
