import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {Route as ReactRoute, RouteProps} from 'react-router-dom';
import {ErrorView} from './ErrorView';

export const Route: React.FC<RouteProps> = (props) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorView}>
      <ReactRoute {...props} />
    </ErrorBoundary>
  );
};
