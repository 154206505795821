import {Input as $Input} from 'antd';
import styled from 'styled-components';


export const SearchBar = styled($Input.Group)`
  && {
    display: flex;
    margin-bottom: 20px;
    &.flex-wrap {
      flex-wrap: wrap;
    }
  }
  && > *:not(.ant-btn) {
    display: inline-flex;
  }

  .ant-btn {
    display: block;
  }
`;
