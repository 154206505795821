import {useMediaQuery as mediaQuery} from 'react-responsive';


export const useMediaQuery = () => {

  return {
    isXs: mediaQuery({query: '(max-width: 450px)'}),
    gtXs: mediaQuery({query: '(min-width: 451px)'}),
    isSm: mediaQuery({query: '(max-width: 600px)'}),
    gtSm: mediaQuery({query: '(min-width: 601px)'}),
    isMobile: mediaQuery({query: '(max-width: 768px)'}),
    isTablet: mediaQuery({query: '(max-width: 1024px)'}),
    isTabletOrDesktop: mediaQuery({query: '(min-width: 768px)'}),
  }
}
