import {QuestionCircleFilled} from '@ant-design/icons/lib';
import {Tooltip} from 'antd';
import {TooltipProps} from 'antd/es/tooltip';
import {colors} from 'lib/colors';
import React from 'react';
import styled from 'styled-components';

interface IProps extends Omit<TooltipProps, 'title'> {
  message?: TooltipProps['title'];
  title?: TooltipProps['title'];
  icon?: JSX.Element | null;
}

export const HintIcon = styled(QuestionCircleFilled)`
  color: ${colors.secondary};
  opacity: 0.3;
`;

export const Hint: React.FC<IProps> = ({
  message,
  title,
  icon = <HintIcon />,
  children,
  ...props
}) => {
  const content: any = title || children || null;
  if (!message) return content;
  return (
    <Tooltip title={message} {...props}>
      {content ? (
        icon ? (
          <span>
            {content} <>{icon}</>
          </span>
        ) : (
          content
        )
      ) : (
        <>{icon}</>
      )}
    </Tooltip>
  );
};
