import {Tooltip, Typography} from 'antd';
import {
  CardBrand,
  ChargeFieldsFragment,
  PaymentMethods,
  SubscriptionFieldsFragment
} from 'api/generated';
import {
  cardBrandLabels,
  paymentMethodIcons,
  paymentMethodLabels,
  paymentMethodMinimalIcons,
  tokenizationMethodLabels,
  tokenizationMethodMinimalIcons
} from 'lib/labels';
import {paymentBrandIcons} from 'lib/paymentBrandIcons';
import React from 'react';
import styled from 'styled-components';
import {QrcodeOutlined} from '@ant-design/icons';
import {isQrPayment} from '../lib/utils';
import {Trans, useTranslation} from 'react-i18next';

const {Text} = Typography;

type Props = {
  item: ChargeFieldsFragment | SubscriptionFieldsFragment;
  minimal?: boolean;
  listView?: boolean;
};

const Dots = styled.span`
  font-family: Helvetica, Arial, sans-serif;
  letter-spacing: 1px;
`;

const BrandPayment = styled.div`
  display: flex;
  align-items: Center;
  grid-column-gap: 8px;
`;

const BrandIcon = styled.img`
  width: 30px;
`;

const CardNumber = styled.div`
  min-width: 40px;
`;

const MethodIcon = styled.img``;

const Spaced = styled.div<{tokenizationMethodIcon: boolean; minimal: boolean}>`
  display: ${(props) => (props.tokenizationMethodIcon ? 'grid' : 'flex')};
  grid-template-columns: ${(props) => (props.minimal ? '28px 80px 32px' : '28px 120px 32px')};
  grid-gap: ${(props) => (props.tokenizationMethodIcon ? '0' : '8px')};
  align-items: center;
`;

export const PaymentMethodOverview: React.FC<Props> = ({item, minimal, listView = true}) => {
  const {paymentMethod} = item;
  const {t} = useTranslation();
  if (!paymentMethod) {
    if (minimal) return null;
    return (
      <Text type="secondary">
        <Trans>No payment method</Trans>
      </Text>
    );
  }
  const brand = paymentMethod?.card?.brand || CardBrand.UNKNOWN;
  const tokenizationMethod = paymentMethod.card?.tokenizationMethod;
  const paymentMethodIcon = (minimal ? paymentMethodMinimalIcons : paymentMethodIcons)[
    paymentMethod.method!
  ];

  const tokenizationMethodIcon =
    tokenizationMethod && tokenizationMethodMinimalIcons[tokenizationMethod];

  return (
    <Spaced tokenizationMethodIcon={!!tokenizationMethodIcon || listView} minimal={!!minimal}>
      {paymentMethodIcon ? (
        <>
          {listView && <div></div>}
          <MethodIcon
            src={paymentMethodIcon.src}
            title={paymentMethodLabels[paymentMethod.method!]}
            style={paymentMethodIcon.style}
          />
        </>
      ) : (
        <>
          {(tokenizationMethodIcon || listView) && (
            <MethodIcon
              src={tokenizationMethodIcon?.src}
              title={tokenizationMethodLabels[tokenizationMethod!]}
              style={{...tokenizationMethodIcon?.style}}
            />
          )}
          <BrandPayment>
            <BrandIcon
              src={paymentBrandIcons[brand]}
              alt={brand}
              title={cardBrandLabels[paymentMethod.card?.brand!]}
            />
            <CardNumber>
              {!minimal && <Dots>••••</Dots>} {paymentMethod?.card?.last4}
            </CardNumber>
          </BrandPayment>
        </>
      )}
      <div>
        {item.__typename === 'Charge' && isQrPayment(item as ChargeFieldsFragment) && (
          <Tooltip title={t('QR Payment')}>
            <QrcodeOutlined />
          </Tooltip>
        )}
      </div>
    </Spaced>
  );
};
