import {UploadOutlined} from '@ant-design/icons';
import {Upload} from 'antd';
import {RcFile, UploadProps} from 'antd/es/upload';
import {UploadFile} from 'antd/es/upload/interface';
import {Auth} from 'aws-amplify';
import {useField} from 'formik';
import React, {useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import {config} from 'stage.config';
import styled from 'styled-components';

type Props = {
  name: string;
  useRawFile?: boolean;
};

const Dragger = styled(Upload.Dragger)<{hidden: boolean}>`
  display: ${(props) => (props.hidden ? 'none' : 'block')};
`;

export const FileInput: React.FC<Props & UploadProps> = ({name, useRawFile, ...props}) => {
  const [field, , {setValue}] = useField(name);
  const [file, setFile] = useState<UploadFile | null>(null);
  const [identityId, setIdentityId] = useState<string | null>(null);
  const isHidden = Boolean(field.value);

  useEffect(() => {
    if (!field.value) {
      setFile(null);
    }
  }, [field.value]);

  useEffect(() => {
    Auth.currentCredentials().then(({identityId}) => {
      setIdentityId(identityId);
    });
  }, []);

  const handleFileUpload = (file: RcFile) => {
    if (useRawFile) {
      setValue(file);
      setFile(file);
      return false;
    }
    if (!identityId) return false;
    const [, , , ext] = /([^.]+)(\.(\w+))?$/.exec(file.name) as string[];
    const key = `private/${identityId}/${name}${ext && '.'}${ext}`;
    setValue({
      key,
      mimeType: file.type,
      localUri: file,
      bucket: config.serviceBucket,
      region: config.region
    });
    setFile(file);
    return false;
  };

  const handleFileRemove = () => {
    setFile(null);
    setValue(null);
  };

  const fileList = file ? [file] : [];

  return (
    <Dragger
      {...props}
      hidden={isHidden}
      fileList={fileList}
      beforeUpload={handleFileUpload}
      onRemove={handleFileRemove}>
      <UploadOutlined /> <Trans>Click here or drag'n'drop your file to this area to upload</Trans>
    </Dragger>
  );
};
