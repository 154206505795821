export const desktopViewport = 'width=1024, shrink-to-fit=yes, maximum-scale=1';
export const mobileViewport =
  'width=device-width, initial-scale=1, shrink-to-fit=yes, maximum-scale=1';

export const mobileLocations: RegExp[] = [
  /onboarding/,
  // /payments\/(.+)/,
  /payments\/new/,
  /subscriptions\/(.+)/,
  /rejected/,
  /suspended/,
  /welcome/,
  /thanks/,
  /connect/,
  /not-configured/,
  /under-review/,
  /^\/$/
];
