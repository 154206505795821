import * as antd from 'antd';
import {Dropdown, Menu} from 'antd';
import {ContactSupportLink} from 'components';
import {AnchorLink} from 'components/AnchorLink';
import {PolicyLink} from 'components/PolicyLink';
import {Spacer} from 'components/Spacer';
import {Stack} from 'components/Stack';
import ISO6391 from 'iso-639-1';
import {defaultLanguage, languages} from 'languages';
import {getPolicyUrl} from 'lib/policyUtils';
import {MenuInfo} from 'rc-menu/es/interface';
import React from 'react';
// @ts-ignore
import CountryFlag from 'react-country-flag';
import {Trans, useTranslation} from 'react-i18next';
import {config} from 'stage.config';
import styled from 'styled-components';
import {useMediaQuery} from '../hooks/useMediaQuery';
import {useUserContext} from '../hooks/useUserContext';

type Props = {};

const CountryName = styled.span`
  margin-left: 5px;
`;

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Footer = styled(antd.Layout.Footer)`
  background-color: #eaeaea;
  border-top: 1px solid #e3e3e3;
  && {
    color: rgba(0, 0, 0, 0.5);
  }
  a {
    color: rgba(0, 0, 0, 0.5);
    &:hover {
      color: rgba(0, 0, 0, 0.8);
    }
  }
  @media (max-width: 768px) {
    padding: 10px 40px;
  }
`;

const flagMap: Record<string, string> = {
  en: 'gb',
  ca: 'es-ca'
};

const CountryLabel: React.FC<{code: string}> = ({code}) => (
  <>
    <CountryFlag
      countryCode={flagMap[code] ? flagMap[code] : code}
      svg
      alt={code}
      cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/"
      cdnSuffix="svg"
    />
    <CountryName>{ISO6391.getNativeName(code)}</CountryName>
  </>
);

export const MainFooter: React.FC<Props> = () => {
  const {i18n} = useTranslation();
  let code = i18n.resolvedLanguage?.split('-')[0];
  if (!languages.includes(code!)) code = defaultLanguage;
  const {isTablet} = useMediaQuery();

  const handleSelectLocale = ({key}: MenuInfo) => {
    i18n.changeLanguage(key as string);
  };

  const langMenu = (
    <Menu selectedKeys={[code!]} onClick={handleSelectLocale}>
      {languages.map((code) => (
        <Menu.Item key={code}>
          <CountryLabel code={code} />
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Footer>
      <Content>
        <Stack gutter={isTablet ? 10 : 30} direction={isTablet ? 'column-reverse' : 'row'}>
          <div>
            &copy;{' '}
            <AnchorLink href="https://monei.com" external>
              MONEI DIGITAL PAYMENTS S.L.
            </AnchorLink>
          </div>
          <Spacer />
          <PolicyLink url={getPolicyUrl(config.termsOfServiceDoc, i18n.resolvedLanguage!)}>
            <Trans>Legal Notice</Trans>
          </PolicyLink>
          <PolicyLink url={getPolicyUrl(config.privacyPolicyDoc, i18n.resolvedLanguage!)}>
            <Trans>Privacy Policy</Trans>
          </PolicyLink>
          <AnchorLink href={config.supportUrl} external>
            <Trans>Support</Trans>
          </AnchorLink>
          <ContactSupportLink>
            <Trans>Contact us</Trans>
          </ContactSupportLink>
          <Dropdown overlay={langMenu} placement="topRight">
            <AnchorLink className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              <CountryLabel code={code!} />
            </AnchorLink>
          </Dropdown>
        </Stack>
      </Content>
    </Footer>
  );
};
