import React from 'react';
import {IAuthState} from 'types';

export enum AccessLevels {
  ADMIN_ACCESS = 'admin_access',
  WRITE_ACCESS = 'write_access',
  READ_ACCESS = 'read_access'
}

export interface IUserContext {
  accountId: string;
  userId: string;
  userEmail: string;
  userPhone?: string;
  promoCode?: string;
  isNew: boolean;
  accessLevels: AccessLevels[];
  privacyPolicyUrl?: string;
  privacyPolicyAcceptedAt?: string;
  termsOfServiceUrl?: string;
  termsOfServiceAcceptedAt?: string;
  preContractUrl?: string;
  preContractAcceptedAt?: string;
  biometricPolicyUrl?: string;
  biometricPolicyAcceptedAt?: string;
  setCurrentUser(user: any): void;
  onSignOut(state?: IAuthState): void;
  storeId?: string;
}

export const UserContext = React.createContext<IUserContext>({
  accountId: '',
  userId: '',
  userEmail: '',
  isNew: false,
  accessLevels: [],
  setCurrentUser: () => {},
  onSignOut: () => {}
});
