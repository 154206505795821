import {useUserContext} from 'hooks/useUserContext';
import React from 'react';
import {IssueType, setZendeskFields, Topic} from '../lib/zendesk';

type Props = {
  subject?: string;
  description?: string;
  issueType?: IssueType;
  topic?: Topic;
  paymentId?: string;
  paymentMethod?: string;
  integrationType?: string;
  errorMessage?: string;
  as?: React.ElementType;
};

export const ContactSupportLink: React.FC<
  Props & React.AnchorHTMLAttributes<HTMLAnchorElement>
> = ({
  children,
  issueType = IssueType.request,
  subject,
  description,
  topic = Topic.general_question,
  paymentId,
  paymentMethod,
  integrationType,
  errorMessage,
  as,
  ...props
}) => {
  const user = useUserContext();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setZendeskFields({
      paymentId,
      paymentMethod,
      integrationType,
      issueType,
      subject,
      topic,
      description,
      errorMessage,
      userEmail: user.userEmail,
      accountId: user.accountId
    });
  };

  const Component = as || 'a';
  return (
    <Component {...props} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
      {children}
    </Component>
  );
};
