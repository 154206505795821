import {ArrowLeftOutlined} from '@ant-design/icons';
import {Divider, Skeleton, Typography} from 'antd';
import {AnchorLink} from 'components/AnchorLink';
import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import styled from 'styled-components';
import {Stack} from './Stack';

const {Title} = Typography;
declare const TITLE_ELE_LIST: [1, 2, 3, 4];

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  min-height: 36px;
  && > * {
    margin-bottom: 0;
  }
  .ant-skeleton {
    width: 50%;
    margin-top: 10px;
  }
  .ant-typography {
    margin: 0;
    line-height: 36px;
    display: flex;
    align-items: center;
  }
`;

const Line = styled(Divider)`
  height: 18px !important;
  top: 0 !important;
  margin: 0;
`;

interface IProps extends RouteComponentProps {
  loading?: boolean;
  title?: JSX.Element | string | null;
  showBack?: boolean;
  backPath?: string;
  level?: typeof TITLE_ELE_LIST[number];
}

const TitleStack = styled(Stack)`
  margin-right: 20px;
`;

const ChildrenStack = styled(Stack)`
  position: relative;
  max-width: calc(100% - 52px);
  flex: 1;
`;

const C: React.FC<IProps> = ({
  loading,
  title,
  children,
  history,
  showBack = true,
  backPath,
  match,
  level
}) => {
  const goBack = () => {
    const path = match.url.split('/');
    path.pop();

    const canGoBack = history.length > 0;
    if (canGoBack) {
      return backPath ? history.push(backPath) : history.goBack();
    }
    return history.push(path.join('/'));
  };

  return (
    <Header>
      <Skeleton loading={loading} paragraph={false} active>
        <TitleStack className="titleStack" gutter={12}>
          {showBack && (
            <>
              <AnchorLink onClick={goBack} style={{lineHeight: '100%'}}>
                <ArrowLeftOutlined style={{fontSize: 18}} />
              </AnchorLink>{' '}
              <Line type="vertical" />
            </>
          )}
          {title && (
            <Title level={level} style={{margin: 0}}>
              {title}
            </Title>
          )}
        </TitleStack>
        <ChildrenStack className="childrenStack" gutter={8}>{children}</ChildrenStack>
      </Skeleton>
    </Header>
  );
};

export const PageHeader = withRouter(C);
