import {Button, Result, Typography} from 'antd';
import {ContactSupportLink} from 'components';
import {Spacer} from 'components/Spacer';
import {Stack} from 'components/Stack';
import React from 'react';
import {FallbackProps} from 'react-error-boundary';
import {useTranslation} from 'react-i18next';
import {Topic} from '../lib/zendesk';

const {Paragraph} = Typography;

export const ErrorView: React.FC<FallbackProps> = ({error, resetErrorBoundary}) => {
  const {t} = useTranslation();
  const stack = error.stack?.split('\n').slice(0, 10).join('<br/>');

  return (
    <Result
      status="error"
      title={t('Oops! Something went wrong!')}
      subTitle={t('Our developers already working to fix this.')}
      extra={
        <Stack>
          <Spacer />
          <ContactSupportLink
            as={Button}
            topic={Topic.report_error}
            subject={error.message}
            description={`URL: ${window.location.href}<br/><br/>${stack}`}>
            Report error
          </ContactSupportLink>
          <Button onClick={resetErrorBoundary}>Reload</Button>
          <Spacer />
        </Stack>
      }>
      <Paragraph type="danger" strong>
        {error.message}
      </Paragraph>
      <Paragraph type="danger">{error.stack}</Paragraph>
    </Result>
  );
};
