import {Input} from 'antd';
import {InputProps} from 'antd/es/input';
import {useField} from 'formik';
import {
  CountryCode,
  formatIncompletePhoneNumber,
  getCountryCallingCode,
  getExampleNumber,
  parseIncompletePhoneNumber
} from 'libphonenumber-js';
// @ts-ignore
import examples from 'libphonenumber-js/examples.mobile.json';
import React, {useCallback, useMemo} from 'react';

type Props = {
  countryCode?: string | null;
  name: string;
  allowEmpty?: boolean;
};

export const PhoneNumberInput: React.FC<Props & InputProps> = ({
  countryCode,
  name,
  allowEmpty,
  ...inputProps
}) => {
  const [field, , {setValue}] = useField(name);
  const code = useMemo(() => {
    if (!countryCode) return '1';
    return getCountryCallingCode(countryCode as CountryCode);
  }, [countryCode]);

  const value = useMemo(() => formatIncompletePhoneNumber(field.value || ''), [field.value]);

  const placeholder = useMemo(
    () => getExampleNumber(countryCode as CountryCode, examples)?.formatInternational(),
    [countryCode]
  );

  const onChange = useCallback(
    (value: string) => {
      if (!value.startsWith('+') && !allowEmpty) {
        value = `+${code}${value}`;
      }
      setValue(parseIncompletePhoneNumber(value));
    },
    [code, setValue, allowEmpty]
  );

  return (
    <Input
      {...field}
      {...inputProps}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
    />
  );
};
