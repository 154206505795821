import omitEmpty from 'omit-empty';
import qs from 'querystring';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {PaymentMethods, TokenizationMethods, useZendeskAuthTokenLazyQuery} from '../api/generated';
import {useUserContext} from '../hooks/useUserContext';
import {config} from '../stage.config';
import {isDefined} from './utils';

type ZendeskConfig = {
  accountId: string;
  language: string;
  getAuthToken: () => Promise<string | undefined>;
};

export enum Topic {
  'account' = 'account',
  'api_integrations' = 'api_integrations',
  'billing' = 'billing',
  'account_verification' = 'account_verification',
  'payment_methods' = 'payment_methods',
  'payment_method_activation' = 'payment_method_activation',
  'payments_orchestration' = 'payments_orchestration',
  'pricing' = 'pricing',
  'qr_code_payments' = 'qr_code_payments',
  'refunds' = 'refunds',
  'settlements' = 'settlements',
  'subscriptions' = 'subscriptions',
  'payments' = 'payments',
  'report_error' = 'report_error',
  'general_question' = 'general_question'
}

export enum IssueType {
  request = 'request',
  complain = 'complain'
}

export type ZendeskFields = {
  subject?: string;
  description?: string;
  issueType?: IssueType;
  topic?: Topic;
  paymentId?: string;
  paymentMethod?: string;
  userEmail?: string;
  accountId?: string;
  integrationType?: string;
  errorMessage?: string;
};

export const paymentMethodTags: Record<string, string> = {
  [PaymentMethods.BIZUM]: 'bizum',
  [PaymentMethods.CARD]: 'credit_card',
  [PaymentMethods.PAYPAL]: 'paypal',
  [PaymentMethods.COFIDIS]: 'cofidis',
  [PaymentMethods.COFIDISLOAN]: 'cofidis_loan',
  [PaymentMethods.BANCONTACT]: 'bancontact',
  [PaymentMethods.IDEAL]: 'ideal',
  [PaymentMethods.SOFORT]: 'sofort',
  [PaymentMethods.GIROPAY]: 'giropay',
  [PaymentMethods.KLARNA]: 'klarna',
  [PaymentMethods.SEPA]: 'sepa',
  [PaymentMethods.MBWAY]: 'mbway',
  [PaymentMethods.MULTIBANCO]: 'multibanco',
  [PaymentMethods.TRUSTLY]: 'trustly',
  [PaymentMethods.EPS]: 'eps',
  [PaymentMethods.BLIK]: 'blik',
  [TokenizationMethods.GOOGLEPAY]: 'google_pay',
  [TokenizationMethods.APPLEPAY]: 'apple_pay',
  [TokenizationMethods.CLICKTOPAY]: 'click_to_pay'
};

const localeMap: Record<string, string> = {
  en: 'en-US',
  ca: 'es'
};

const getFullLocale = (language: string) => {
  if (!language) return undefined;
  if (localeMap[language]) return localeMap[language];
  return language;
};

export const initZendeskWidget = ({language, accountId, getAuthToken}: ZendeskConfig) => {
  const zE = window.zE;
  if (!zE) return;
  try {
    zE('messenger', 'loginUser', function (callback: (jwt?: string) => void) {
      getAuthToken().then(callback);
    });
    zE('messenger:set', 'conversationFields', [
      {id: 4451372321809, value: 'has_account'},
      {id: 4417061715857, value: accountId}
    ]);
    zE('messenger:set', 'locale', getFullLocale(language));
    zE('messenger:set', 'zIndex', 10);
  } catch (error) {
    console.log(error);
  }
};

export const updateZendeskWidgetLanguage = (language: string, attempt = 0) => {
  const zE = window.zE;
  let timeout: NodeJS.Timeout;
  if (!zE) {
    if (attempt > 10) {
      console.error('[Zendesk] widget not found');
      return;
    }
    timeout = setTimeout(() => updateZendeskWidgetLanguage(language, attempt + 1), 500);
    return () => clearTimeout(timeout);
  }
  try {
    zE('messenger:set', 'locale', getFullLocale(language));
  } catch (error) {
    console.log(error);
  }
  return () => clearTimeout(timeout);
};

export const logoutZendeskWidget = () => {
  const zE = window.zE;
  if (!zE) return;
  try {
    zE('messenger', 'logoutUser');
  } catch (error) {
    console.log(error);
  }
};

export const hideZendeskWidget = () => {
  const zE = window.zE;
  if (!zE) return;
  try {
    zE('messenger', 'hide');
  } catch (error) {
    console.log(error);
  }
};

export const setZendeskFields = ({
  paymentId,
  paymentMethod,
  integrationType,
  issueType,
  subject,
  topic,
  description,
  userEmail,
  accountId,
  errorMessage
}: ZendeskFields) => {
  const paymentMethodTag = paymentMethodTags[paymentMethod as string];
  const query = qs.stringify(
    omitEmpty({
      ticket_form_id:
        issueType === 'request' ? 360000322338 : issueType === 'complain' ? 7544217001105 : '-',
      tf_anonymous_requester_email: userEmail,
      tf_4417061715857: accountId,
      tf_4450339294609: topic,
      tf_4417073746577: paymentId,
      tf_4451275090705: paymentMethodTag,
      tf_4451051279505: integrationType,
      tf_4451372321809: 'has_account',
      tf_27097569134481: errorMessage,
      tf_subject: subject,
      tf_description: description
    }) as any
  );

  const supportFormUrl = `${config.supportFormUrl}?${query}`;

  const zE = window.zE;
  if (!zE) {
    window.open(supportFormUrl, '_blank');
  }

  const fields = [
    {id: 4450339294609, value: topic},
    {id: 4417073746577, value: paymentId},
    {id: 4451275090705, value: paymentMethodTag},
    {id: 4451051279505, value: integrationType},
    {id: 27097569134481, value: errorMessage},
    {id: 4451372321809, value: 'has_account'}
  ].filter((f) => isDefined(f.value));

  try {
    zE('messenger:set', 'conversationFields', fields);
    zE('messenger', 'open', 'Test message');
    zE('messenger:on', 'close', () => {
      zE('messenger:set', 'conversationFields', [{id: 4451372321809, value: 'has_account'}]);
    });
  } catch (error) {
    console.log(error);
    window.open(supportFormUrl, '_blank');
  }
};

export const useZendeskWidget = () => {
  const {i18n} = useTranslation();
  const user = useUserContext();
  const [getZendeskAuthToken] = useZendeskAuthTokenLazyQuery();

  useEffect(() => {
    initZendeskWidget({
      accountId: user.accountId,
      language: i18n.language,
      getAuthToken: async () => {
        const {data} = await getZendeskAuthToken();
        return data?.zendeskAuthToken || undefined;
      }
    });
  }, [getZendeskAuthToken, i18n.language, user.accountId]);
};
