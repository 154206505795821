type Colors = {
  primary: string;
  secondary: string;
  success: string;
  red: string;
  grey: string;
  darkGrey: string;
  yellow: string;
  green: string;
  [key: string]: string;
};

export const colors: Colors = {
  primary: '#8961a5',
  secondary: 'rgba(0, 0, 0, 0.45)',
  success: '#74ebd5',
  error: '#fecaca',
  red: '#f5222d',
  grey: '#e3e8ee',
  darkGrey: '#ccd1d7',
  yellow: '#fae7bb',
  green: '#1ea672'
};
