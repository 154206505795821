import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import {Typography} from 'antd';
import {AccountInvoicePaymentMethod, AddressFieldsFragment} from 'api/generated';
import {AnchorLink} from 'components';
import {EmailLink} from 'components/EmailLink';
import {friendlyFormatIBAN} from 'ibantools';
import {amountFromInt, getHostname} from 'lib/utils';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import {CountryCode} from 'libphonenumber-js/types';
import moment from 'moment-timezone';
import React from 'react';
import {Trans} from 'react-i18next';

const {Text} = Typography;

export const formatWebsite = (website?: string | null) => {
  if (!website) return null;
  let hostname = getHostname(website);
  return (
    <AnchorLink href={website} external>
      {hostname}
    </AnchorLink>
  );
};

export const formatPhone = (phone?: string | null, countryCode?: string | null) => {
  if (!phone) return null;
  return parsePhoneNumberFromString(phone, countryCode as CountryCode)?.formatInternational();
};

export const formatIBAN = (iban?: string | null, last4?: boolean) => {
  if (!iban) return null;
  if (last4) return `•••• ${iban.slice(-4)}`;
  return friendlyFormatIBAN(iban);
};

export const formatEmail = (email?: string | null) => {
  if (!email) return null;
  return <EmailLink email={email} />;
};

export const formatAddress = (address?: AddressFieldsFragment | null) => {
  if (!address) return null;
  const {line1, line2, city, country, state, zip} = address;
  const lines: any[] = [];
  if (line1) lines.push(line1);
  if (line2) lines.push(line2);
  if (city || state || zip || country) {
    const line: string[] = [];
    if (city) line.push(city);
    if (state) line.push(state);
    if (zip) line.push(zip);
    if (country) line.push(country);
    lines.push(line.join(', '));
  }
  if (lines.length === 0) return null;
  return lines.reduce((a, b, i) => [a, <br key={i} />, b]);
};

export const formatAmount = (
  amount: number | undefined | null,
  currency = 'EUR',
  precision = 0
) => {
  if (!amount) amount = 0;
  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision
  }).format(amount);
};

export const formatIntAmount = (
  amount: number | undefined | null,
  currency = 'EUR',
  precision = 2
) => {
  if (!amount) amount = 0;
  return formatAmount(amountFromInt(amount), currency, precision);
};

export const formatPercent = (value: number | undefined | null) => {
  if (!value) value = 0;
  return new Intl.NumberFormat(undefined, {
    style: 'percent'
  }).format(value / 100);
};

export const formatUnixDate = (
  date: number | null | undefined,
  fromNow = false,
  format: string = 'lll'
) => {
  if (!date) return null;
  if (fromNow) return moment.unix(date).fromNow();
  return moment.unix(date).format(format);
};

export const formatDateRange = (from: number, to: number, format = 'LL') => {
  return `${moment.unix(from).utc().format(format)} - ${moment.unix(to).utc().format(format)}`;
};

export const formatInvoicePaymentMethod = (paymentMethod?: AccountInvoicePaymentMethod | null) => {
  if (!paymentMethod) return <Trans>Direct debit</Trans>;
  switch (paymentMethod) {
    case AccountInvoicePaymentMethod.BANK_TRANSFER:
      return <Trans>Bank transfer</Trans>;
    default:
      return <Trans>Direct debit</Trans>;
  }
};

export const formatBoolean = (
  isTrue?: boolean | null,
  trueLabel = 'Enabled',
  falseLabel = 'Disabled'
) => {
  return isTrue ? (
    <Text type="success" strong>
      <CheckOutlined /> <span>{trueLabel}</span>
    </Text>
  ) : (
    <Text type="danger" strong>
      <CloseOutlined /> <span>{falseLabel}</span>
    </Text>
  );
};

export const removeSpecialChars = (value: string) => {
  return value.replace(/[\[\]`·~!@#$%^&*()-+{}|<>"'=+;:ªº¨\/?]/g, '');
};
