import {useApolloClient} from '@jimmyn/apollo-client';
import {AccountStatus, useLiveAccountQuery} from 'api/generated';
import {accountQuery} from 'api/queries';
import createPersistedState from 'use-persisted-state';

const useTestMode = createPersistedState('m-test-mode');

type Result = {
  isTestMode: boolean | null;
  isNotLive: boolean | null;
  setTestMode: (enabled: boolean) => Promise<void>;
};

export const useTestModeState = (initialState: boolean | null = null): Result => {
  const [isTestMode, setTestMode] = useTestMode(initialState);
  const {data: liveAccountData} = useLiveAccountQuery({skip: !isTestMode});
  const client = useApolloClient();
  const isNotLive = liveAccountData?.liveAccount.status === AccountStatus.PENDING_CONTRACT;

  const handleChange = async (enabled: boolean) => {
    setTestMode(enabled);
    try {
      if (isNotLive) {
        await client.query({query: accountQuery, fetchPolicy: 'network-only'});
      } else {
        await client.resetStore();
      }
    } catch {}
  };

  return {
    isTestMode: Boolean(isTestMode),
    isNotLive,
    setTestMode: handleChange
  };
};
