import dot from 'dot-object';
import {useFormikContext} from 'formik';
import {scrollToError} from 'lib/utils';
import React, {useEffect} from 'react';

type Props = {
  offset?: number;
  parent?: HTMLElement;
};

export const ScrollToError: React.FC<Props> = ({offset = 150, parent = window}) => {
  const {errors, isValid, isSubmitting, setFieldTouched} = useFormikContext();

  useEffect(() => {
    if (isValid || !isSubmitting) return;
    const errorKey = Object.keys(dot.dot(errors))[0];
    if (!errorKey) return;
    setFieldTouched(errorKey);
    scrollToError(errorKey, parent, offset);
  }, [isValid, isSubmitting]);

  return null;
};
