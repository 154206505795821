import {Input} from 'antd';
import {InputProps} from 'antd/es/input';
import {useField} from 'formik';
import {friendlyFormatIBAN} from 'ibantools';
import React, {useCallback, useMemo} from 'react';

type Props = {
  countryCode?: string | null;
  name: string;
};

export const IBANInput: React.FC<Props & InputProps> = ({countryCode, name, ...inputProps}) => {
  const [field, , {setValue}] = useField(name);

  const value = useMemo(() => friendlyFormatIBAN(field.value), [field.value]);

  const onChange = useCallback(
    (value: string) => {
      setValue(value.replace(/ /g, ''));
    },
    [setValue]
  );

  const placeholder = useMemo(() => {
    return friendlyFormatIBAN(`${countryCode}9121000418450200051332`);
  }, [countryCode]);

  return (
    <Input
      placeholder={placeholder || undefined}
      {...field}
      {...inputProps}
      value={value || undefined}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};
