import {Radio} from 'antd';
import {CheckboxOptionType} from 'antd/lib/checkbox/Group';
import {colors} from 'lib/colors';
import {lighten} from 'polished';
import React from 'react';
import styled from 'styled-components';

const RadioGroup = Radio.Group;

const Label = styled.span`
  display: block;
  font-weight: 500;
  font-size: 1.1em;
  margin-bottom: 2px;
`;
const Description = styled.span`
  display: block;
  margin-bottom: 2px;
`;
const RadioOption = styled(Radio)`
  &&[class$='wrapper-checked'] {
    border: 1px solid ${lighten(0.3, colors.primary)};
    background-color: ${lighten(0.59, colors.primary)};
  }
  &&:last-child {
    margin-right: 0;
  }
  && {
    flex: 1;
    white-space: normal;
    display: flex;
    align-items: flex-start;
    border-radius: 2px;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    transition: all 0.3s;
    padding: 10px 16px;
    zoom: 1;
  }
  .ant-radio {
    margin-top: 5px;
  }
`;

export const ChoicesGroup = styled(RadioGroup)`
  && {
    display: flex;
    margin-bottom: 20px;
  }
`;

export const Choice: React.FC<CheckboxOptionType> = ({label, children, ...props}) => (
  <RadioOption {...props}>
    <Label>{label}</Label>
    {children && <Description>{children}</Description>}
  </RadioOption>
);
