import {Table} from 'antd';
import {TableProps} from 'antd/es/table';
import styled from 'styled-components';

export const SimpleTable = styled(Table)<TableProps<any>>`
  border: 1px solid #e8e8e8;
  border-radius: 4px 4px;
  margin-bottom: 20px;

  tr:last-child td {
    border-bottom: none;
  }

  & .ant-table-placeholder {
    border-bottom: 0;
  }
`;
