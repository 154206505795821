import {colors} from 'lib/colors';
import styled from 'styled-components';

type ParagraphProps = {
  type?: 'primary' | 'secondary' | 'success' | 'error';
};

export const Paragraph = styled.p<ParagraphProps>`
  margin-top: 0;
  margin-bottom: 1em;
  color: ${(props) => (props.type ? colors[props.type] : 'inherit')};
`;
