import {
  accountFields,
  accountInvoiceFields,
  accountSettlementFields,
  authorizedUserFields,
  chargeEventObjectFields,
  chargeFields,
  documentFields,
  liveAccountFields,
  providerFields,
  storeFields,
  subscriptionEventObjectFields,
  subscriptionFields
} from 'api/fragments';
import gql from 'graphql-tag';

export const accountQuery = gql`
  query Account {
    account {
      ...AccountFields
    }
  }
  ${accountFields}
`;

export const liveAccountQuery = gql`
  query LiveAccount {
    liveAccount {
      ...LiveAccountFields
    }
  }
  ${liveAccountFields}
`;

export const authorizedUsersQuery = gql`
  query AuthorizedUsers($token: String, $pageSize: Int) {
    authorizedUsers(token: $token, pageSize: $pageSize) {
      items {
        ...AuthorizedUserFields
      }
      nextToken
    }
  }
  ${authorizedUserFields}
`;

export const chargesQuery = gql`
  query Charges($filter: SearchableChargeFilterInput, $size: Int, $from: Int) {
    charges(filter: $filter, size: $size, from: $from) {
      items {
        ...ChargeFields
      }
      total
    }
  }
  ${chargeFields}
`;

export const chargeQuery = gql`
  query Charge($id: ID!) {
    charge(id: $id) {
      ...ChargeFields
    }
  }
  ${chargeFields}
`;

export const succeededDateRangeKpiQuery = gql`
  query SucceededDateRangeKpi(
    $start: Int
    $end: Int
    $interval: Interval
    $timezone: String
    $storeId: ID
    $currency: Currencies
  ) {
    chargesDateRangeKPI(
      start: $start
      end: $end
      interval: $interval
      timezone: $timezone
      storeId: $storeId
      currency: $currency
    ) {
      currency
      total {
        succeededAmount
        succeededCount
      }
      data {
        timestamp
        cuSucceededAmount
        cuSucceededCount
      }
    }
  }
`;

export const chargesDateRangeKpiQuery = gql`
  query ChargesDateRangeKpi(
    $start: Int
    $end: Int
    $interval: Interval
    $timezone: String
    $storeId: ID
    $currency: Currencies
  ) {
    chargesDateRangeKPI(
      start: $start
      end: $end
      interval: $interval
      timezone: $timezone
      storeId: $storeId
      currency: $currency
    ) {
      currency
      total {
        succeededAmount
        succeededCount
        refundedCount
        failedCount
      }
      data {
        timestamp
        succeededAmount
        succeededCount
        refundedCount
        failedCount
      }
    }
  }
`;

export const chargeEventsQuery = gql`
  query ChargeEvents($chargeId: ID!, $size: Int, $from: Int) {
    chargeEvents(chargeId: $chargeId, size: $size, from: $from) {
      items {
        id
        type
        accountId
        livemode
        objectId
        objectType
        createdAt
        object {
          ...ChargeEventObjectFields
        }
      }
      total
    }
  }
  ${chargeEventObjectFields}
`;

export const eventsQuery = gql`
  query Events($filter: SearchableEventFilterInput, $size: Int, $from: Int) {
    events(filter: $filter, size: $size, from: $from) {
      items {
        id
        type
        accountId
        livemode
        objectId
        objectType
        createdAt
        object {
          ...ChargeEventObjectFields
        }
      }
      total
    }
  }
  ${chargeEventObjectFields}
`;

export const countriesQuery = gql`
  query Countries {
    countries {
      supportedCountries {
        name
        phoneCode
        code
      }
      currentCountry {
        name
        phoneCode
        code
      }
      currentCountryCode
      isSupported
    }
  }
`;

export const previewPaymentEmailQuery = gql`
  query PreviewPaymentEmail($paymentId: ID!, $emailType: PaymentEmailType, $language: String) {
    previewPaymentEmail(paymentId: $paymentId, emailType: $emailType, language: $language)
  }
`;

export const providersQuery = gql`
  query Providers {
    providers {
      ...ProviderFields
    }
  }
  ${providerFields}
`;

export const providerQuery = gql`
  query Provider($id: ID!) {
    provider(id: $id) {
      ...ProviderFields
    }
  }
  ${providerFields}
`;

export const availablePaymentMethodsQuery = gql`
  query AvailablePaymentMethods {
    availablePaymentMethods {
      paymentMethod
      configured
      enabled
    }
  }
`;

export const availableProcessorsQuery = gql`
  query AvailableProcessors($paymentMethod: PaymentMethods!) {
    availableProcessors(paymentMethod: $paymentMethod) {
      paymentMethod
      processor
      configured
      configurable
      enabled
    }
  }
`;

export const providerConfigQuery = gql`
  query ProviderConfig($paymentMethod: PaymentMethods!, $processor: Processor!) {
    providerConfig(paymentMethod: $paymentMethod, processor: $processor) {
      paymentMethod
      processor
      acquirers
      metadataConfig {
        key
        type
        label
        possibleValues
        regex
        secret
        required
        example
        default
        internal
      }
    }
  }
`;

export const accountInvoiceQuery = gql`
  query AccountInvoice($id: ID!) {
    accountInvoice(id: $id) {
      ...AccountInvoiceFields
    }
  }
  ${accountInvoiceFields}
`;

export const accountInvoicesQuery = gql`
  query AccountInvoices($filter: SearchableAccountInvoiceFilterInput, $size: Int, $from: Int) {
    accountInvoices(filter: $filter, size: $size, from: $from) {
      items {
        ...AccountInvoiceFields
      }
      total
    }
  }
  ${accountInvoiceFields}
`;

export const userAccountsQuery = gql`
  query UserAccounts($token: String, $pageSize: Int) {
    userAccounts(token: $token, pageSize: $pageSize) {
      items {
        accessLevel
        accountId
        accountName
      }
      nextToken
    }
  }
`;

export const storesQuery = gql`
  query Stores($token: String, $pageSize: Int) {
    stores(token: $token, pageSize: $pageSize) {
      items {
        ...StoreFields
      }
      nextToken
    }
  }
  ${storeFields}
`;

export const storeQuery = gql`
  query Store($id: ID!) {
    store(id: $id) {
      ...StoreFields
    }
  }
`;

export const userStoreQuery = gql`
  query UserStore {
    userStore {
      ...StoreFields
    }
  }
  ${storeFields}
`;

export const accountSettlementQuery = gql`
  query AccountSettlement($id: ID!) {
    accountSettlement(id: $id) {
      ...AccountSettlementFields
    }
  }
  ${accountSettlementFields}
`;
export const accountSettlementsQuery = gql`
  query AccountSettlements(
    $filter: SearchableAccountSettlementFilterInput
    $size: Int
    $from: Int
  ) {
    accountSettlements(filter: $filter, size: $size, from: $from) {
      items {
        ...AccountSettlementFields
      }
      total
    }
  }
  ${accountSettlementFields}
`;
export const settlementEvents = gql`
  query SettlementEvents($settlementId: ID!, $size: Int, $from: Int) {
    settlementEvents(settlementId: $settlementId, size: $size, from: $from) {
      items {
        id
        type
        accountId
        objectId
        objectType
        object {
          accountId
          acquirerFees
          createdAt
          grossVolume
          iban
          id
          legalName
          netVolume
          period
          periodEndAt
          periodStartAt
          previousNegative
          releasedAmount
          reservedAmount
          reservePercent
          scheduledAt
          status
          updatedAt
        }
        createdAt
      }
      total
    }
  }
`;

export const chargesExportQuery = gql`
  query ChargesExport($id: ID!) {
    chargesExport(id: $id) {
      id
      status
      statusCode
      statusMessage
    }
  }
`;

export const documentQuery = gql`
  query Document($id: ID!) {
    document(id: $id) {
      ...DocumentFields
    }
  }
  ${documentFields}
`;
export const documentsQuery = gql`
  query Documents($token: String, $pageSize: Int) {
    documents(token: $token, pageSize: $pageSize) {
      items {
        ...DocumentFields
      }
      nextToken
    }
  }
  ${documentFields}
`;

export const subscriptionsQuery = gql`
  query Subscriptions($filter: SearchableSubscriptionFilterInput, $size: Int, $from: Int) {
    subscriptions(filter: $filter, size: $size, from: $from) {
      items {
        ...SubscriptionFields
      }
      total
    }
  }
  ${subscriptionFields}
`;

export const subscriptionQuery = gql`
  query Subscription($id: ID!) {
    subscription(id: $id) {
      ...SubscriptionFields
    }
  }
  ${subscriptionFields}
`;

export const banksQuery = gql`
  query Banks($country: String!) {
    banks(country: $country) {
      id
      name
      logo
    }
  }
`;

export const chargeSourcesQuery = gql`
  query ChargeSources {
    chargeSources
  }
`;

export const subscriptionEventsQuery = gql`
  query SubscriptionEvents($subscriptionId: ID!, $size: Int, $from: Int) {
    subscriptionEvents(subscriptionId: $subscriptionId, size: $size, from: $from) {
      items {
        id
        type
        accountId
        livemode
        objectId
        objectType
        createdAt
        object {
          ...SubscriptionEventObjectFields
        }
      }
      total
    }
  }
  ${subscriptionEventObjectFields}
`;

export const previewSubscriptionEmailQuery = gql`
  query PreviewSubscriptionEmail(
    $subscriptionId: ID!
    $emailType: SubscriptionEmailType
    $language: String
  ) {
    previewSubscriptionEmail(
      subscriptionId: $subscriptionId
      emailType: $emailType
      language: $language
    )
  }
`;

export const notificationsPortalUrlQuery = gql`
  query NotificationsPortalUrl {
    notificationsPortalUrl {
      redirectUrl
    }
  }
`;

export const webhooksQuery = gql`
  query Webhooks($token: String, $pageSize: Int) {
    webhooks(token: $token, pageSize: $pageSize) {
      items {
        id
        url
        eventTypes
        enabled
        updatedAt
      }
      nextToken
    }
  }
`;

export const zendeskAuthToken = gql`
  query ZendeskAuthToken {
    zendeskAuthToken
  }
`;
