import {CloseOutlined, UploadOutlined} from '@ant-design/icons';
import {Button, notification, Upload} from 'antd';
import {UploadProps} from 'antd/es/upload';
import {UploadFile} from 'antd/es/upload/interface';
import {Storage} from 'aws-amplify';
import {useField} from 'formik';
import numeral from 'numeral';
import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import styled from 'styled-components';
import sanitizeSVG from '@mattkrick/sanitize-svg';

type Props = {
  name: string;
  bgColor?: string | null;
  allowedTypes?: string[];
  maxFileSize?: number;
  maxWidth?: number;
  maxHeight?: number;
  minWidth?: number;
  minHeight?: number;
  width?: number;
};

const getFileKey = (fileUrl?: string) => {
  if (!fileUrl) return null;
  return fileUrl.split('/').pop()!;
};

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: 100%;
`;

const DeleteButton = styled(Button)`
  position: absolute;
  right: -18px;
  top: -18px;
  display: none;
`;

const ImageContainer = styled.div<{bgColor?: string | null; width?: number}>`
  display: inline-block;
  position: relative;
  max-width: ${(props) => `${props.width || 300}px`};
  padding: 20px;
  background-color: ${(props) => props.bgColor || 'transparent'};
  margin-bottom: 10px;

  &:hover {
    button {
      display: block;
    }
  }
`;

export const ImageInput: React.FC<Props & UploadProps> = ({
  name,
  maxFileSize = 1024 * 1024,
  allowedTypes = ['image/jpeg', 'image/png', 'image/svg+xml'],
  ...props
}) => {
  const [field, meta, {setValue}] = useField(name);
  const [preview, setPreview] = useState<string | null>(null);
  const fileKey = getFileKey(field.value);
  const fileUrl = preview || field.value;
  const {t} = useTranslation();

  const handleRemove = async () => {
    if (!fileKey) return;
    setValue(null);
    return Storage.remove(fileKey, {level: 'protected'});
  };

  const handleUpload = async (file: UploadFile) => {
    setPreview(URL.createObjectURL(file as any));
    const [, , , ext] = /([^.]+)(\.(\w+))?$/.exec(file.name) as string[];
    const fileName = `${file.uid}${ext && '.'}${ext}`;
    try {
      await Storage.put(fileName, file, {
        level: 'protected',
        contentType: file.type,
        cacheControl: 'public,max-age=31536000'
      });
      const signedUrl = await Storage.get(fileName, {level: 'protected'});
      const url = (signedUrl as string).split('?')[0];
      setValue(url);
      setPreview(null);
    } catch (error: any) {
      console.log(error);
    }
  };

  const validateMimeType = (file: UploadFile) => {
    if (!file) return false;
    if (!file.type || !allowedTypes!.includes(file.type)) {
      notification.error({
        message: t('Unsupported file type', {ns: 'translation'})
      });
      return false;
    }
    return true;
  };

  const validateSvg = async (file: UploadFile) => {
    if (file.type !== 'image/svg+xml') return true;
    const cleanImage = await sanitizeSVG(file as any);
    if (!cleanImage) {
      notification.error({
        message: t('Unsupported file type', {ns: 'translation'})
      });
      return false;
    }
    return true;
  };

  const validateFileSize = (file: UploadFile) => {
    if (!file) return false;
    const size = file.size!;
    if (size > maxFileSize) {
      notification.error({
        message: t('File size is too large', {ns: 'translation'}),
        description: t('Max file size is {{maxFileSize}}', {
          ns: 'translation',
          maxFileSize: numeral(maxFileSize).format('0.0 b')
        })
      });
      return false;
    }
    return true;
  };

  const validateDimensions = (file: UploadFile) => {
    if (!file) return false;
    const {maxWidth = 500, maxHeight = 500, minHeight = 0, minWidth = 0} = props;
    return new Promise((resolve) => {
      if (file.type === 'image/svg+xml') return resolve(true);
      const img = new Image();
      img.onload = () => {
        if (img.width > maxWidth || img.height > maxHeight) {
          notification.error({
            message: t('Image dimensions are too large', {ns: 'translation'}),
            description: t('Max image dimensions are {{maxWidth}}x{{maxHeight}}', {
              ns: 'translation',
              maxWidth,
              maxHeight
            })
          });
          resolve(false);
        } else if (img.width < minWidth || img.height < minHeight) {
          notification.error({
            message: t('Image dimensions are too small', {ns: 'translation'}),
            description: t('Min image dimensions are {{minWidth}}x{{minHeight}}', {
              ns: 'translation',
              minWidth,
              minHeight
            })
          });
          resolve(false);
        } else {
          resolve(true);
        }
      };
      // @ts-ignore
      img.src = URL.createObjectURL(file);
    });
  };

  const beforeUpload = async (file: UploadFile) => {
    if (!file.type) return Upload.LIST_IGNORE;
    if (!validateMimeType(file)) return Upload.LIST_IGNORE;
    if (!validateFileSize(file)) return Upload.LIST_IGNORE;
    const isValidDimensions = await validateDimensions(file);
    if (!isValidDimensions) return Upload.LIST_IGNORE;
    const isValidSvg = await validateSvg(file);
    if (!isValidSvg) return Upload.LIST_IGNORE;
    // noinspection JSIgnoredPromiseFromCall
    await handleUpload(file);
    return false;
  };

  return (
    <>
      {fileUrl ? (
        <>
          <ImageContainer bgColor={props.bgColor} width={props.width}>
            <StyledImage src={fileUrl} alt={name} />
            <DeleteButton shape="circle" onClick={handleRemove}>
              <CloseOutlined />
            </DeleteButton>
          </ImageContainer>
        </>
      ) : (
        <Upload {...props} beforeUpload={beforeUpload} showUploadList={false} accept="image/*">
          <Button>
            <UploadOutlined /> <Trans>Click to upload</Trans>
          </Button>
        </Upload>
      )}
    </>
  );
};
