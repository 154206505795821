import {Button} from 'antd';
import {NativeButtonProps} from 'antd/lib/button/button';
import React, {MouseEvent} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';

interface IButtonLinkProps extends NativeButtonProps {
  to: string;
}

const C: React.FC<IButtonLinkProps & RouteComponentProps<any>> = ({
  to,
  history,
  staticContext,
  match,
  location,
  onClick,
  ...props
}) => (
  <Button
    {...props}
    htmlType="button"
    onClick={(e: MouseEvent<any>) => {
      e.preventDefault();
      onClick && onClick(e);
      history.push(to);
    }}
    href={to}
  />
);

export const ButtonLink = withRouter(C);
