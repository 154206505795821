import {LoadingOutlined} from '@ant-design/icons';
import {message, notification, Spin} from 'antd';
import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import {setLocale} from 'yup';

// kick off the polyfill!
smoothscroll.polyfill();

Spin.setDefaultIndicator(<LoadingOutlined style={{fontSize: 32}} spin />);

notification.config({
  top: 70
});

message.config({
  top: 70
});

setLocale({
  mixed: {
    required: 'this field is required',
    default: 'this field is required'
  },
  string: {
    email: 'must be a valid email',
    url: 'must be a valid URL'
  },
  date: {
    min: 'must be later than ${min}',
    max: 'must be at earlier than ${max}'
  }
});
