import {
  Acquirer,
  BillingCategoryKlarna,
  CardBrand,
  CardType,
  PaymentMethods,
  Processor,
  SubscriptionStatus,
  ThreeDSecureFlow,
  TokenizationMethods
} from 'api/generated';
import adyen from '../images/adyen.svg';
import applePay from '../images/apple-pay.svg';
import bizum from '../images/bizum.svg';
import googlePay from '../images/google-pay.svg';
import clickToPay from '../images/click-to-pay.svg';
import monei_x from '../images/monei-x.svg';
import paypal from '../images/paypal.svg';
import redsys from '../images/redsys.svg';
import stripe from '../images/stripe.svg';
import cofidis from '../images/cofidis.svg';
import cofidisLoan from '../images/cofidis-loan.svg';
import multibanco from '../images/multibanco.svg';
import multibancoSmall from '../images/multibanco-small.svg';
import applePaySmall from 'images/apple-pay-small.svg';
import googlePaySmall from 'images/google-pay-small.svg';
import klarna from '../images/klarna-badge.svg';
import ideal from '../images/ideal.svg';
import giropay from '../images/giropay.svg';
import mbway from '../images/mbway.svg';
import sofort from '../images/sofort.svg';
import sepa from '../images/sepa.svg';
import trustly from '../images/trustly.svg';
import bancontact from '../images/bancontact.svg';
import bancontactSmall from '../images/bancontact-small.svg';
import eps from '../images/eps.svg';
import blik from '../images/blik.svg';
import {TFunction} from 'i18next';

export const cardBrandLabels: {[key in CardBrand]: string} = {
  [CardBrand.VISA]: 'Visa',
  [CardBrand.AMEX]: 'American Express',
  [CardBrand.DINERS]: 'Diners Club',
  [CardBrand.JCB]: 'JCB',
  [CardBrand.MASTERCARD]: 'Mastercard',
  [CardBrand.UNIONPAY]: 'UnionPay',
  [CardBrand.DISCOVER]: 'Discover',
  [CardBrand.UNKNOWN]: 'Unknown'
};

export const paymentMethodLabels: {[key: string]: string} = {
  [PaymentMethods.BIZUM]: 'Bizum',
  [PaymentMethods.CARD]: 'Credit card',
  [PaymentMethods.PAYPAL]: 'PayPal',
  [PaymentMethods.COFIDIS]: 'Cofidis 4xcard',
  [PaymentMethods.COFIDISLOAN]: 'Cofidis 6x12x24x',
  [PaymentMethods.BANCONTACT]: 'Bancontact',
  [PaymentMethods.IDEAL]: 'iDEAL',
  [PaymentMethods.SOFORT]: 'Sofort',
  [PaymentMethods.GIROPAY]: 'Giropay',
  [PaymentMethods.KLARNA]: 'Klarna',
  [PaymentMethods.SEPA]: 'SEPA',
  [PaymentMethods.MBWAY]: 'MBWay',
  [PaymentMethods.MULTIBANCO]: 'Multibanco',
  [PaymentMethods.TRUSTLY]: 'Trustly',
  [PaymentMethods.EPS]: 'EPS',
  [PaymentMethods.BLIK]: 'BLIK'
};

export const tokenizationMethodLabels: {[key: string]: string} = {
  [TokenizationMethods.GOOGLEPAY]: 'Google Pay',
  [TokenizationMethods.APPLEPAY]: 'Apple Pay',
  [TokenizationMethods.CLICKTOPAY]: 'Click to Pay'
};

export const cardTypeLabels: {[key in CardType]: string} = {
  [CardType.CREDIT]: 'Credit',
  [CardType.DEBIT]: 'Debit'
};

export const currencySymbols: {[key: string]: string} = {
  EUR: '€',
  USD: '$'
};

export const paymentMethodIcons: {[key: string]: any} = {
  [PaymentMethods.BIZUM]: {src: bizum, style: {height: 18}},
  [PaymentMethods.PAYPAL]: {src: paypal, style: {height: 18}},
  [PaymentMethods.COFIDIS]: {src: cofidis, style: {height: 32}},
  [PaymentMethods.COFIDISLOAN]: {src: cofidisLoan, style: {height: 32}},
  [PaymentMethods.MULTIBANCO]: {src: multibanco, style: {height: 22}},
  [PaymentMethods.KLARNA]: {src: klarna, style: {height: 22}},
  [PaymentMethods.IDEAL]: {src: ideal, style: {height: 32}},
  [PaymentMethods.GIROPAY]: {src: giropay, style: {height: 26}},
  [PaymentMethods.MBWAY]: {src: mbway, style: {height: 22}},
  [PaymentMethods.SOFORT]: {src: sofort, style: {height: 14}},
  [PaymentMethods.SEPA]: {src: sepa, style: {height: 16}},
  [PaymentMethods.TRUSTLY]: {src: trustly, style: {height: 16}},
  [PaymentMethods.BANCONTACT]: {src: bancontact, style: {height: 14}},
  [PaymentMethods.EPS]: {src: eps, style: {height: 32}},
  [PaymentMethods.BLIK]: {src: blik, style: {height: 24}}
};

export const tokenizationMethodIcons: {[key: string]: any} = {
  [TokenizationMethods.GOOGLEPAY]: {src: googlePay, style: {height: 18}},
  [TokenizationMethods.APPLEPAY]: {src: applePay, style: {height: 18}},
  [TokenizationMethods.CLICKTOPAY]: {src: clickToPay, style: {height: 16, marginRight: 8}}
};

export const paymentMethodMinimalIcons: {[key: string]: any} = {
  [PaymentMethods.BIZUM]: {src: bizum, style: {height: 18}},
  [PaymentMethods.PAYPAL]: {src: paypal, style: {height: 18}},
  [PaymentMethods.COFIDIS]: {src: cofidis, style: {height: 32}},
  [PaymentMethods.COFIDISLOAN]: {src: cofidisLoan, style: {height: 32}},
  [PaymentMethods.MULTIBANCO]: {src: multibancoSmall, style: {height: 22}},
  [PaymentMethods.KLARNA]: {src: klarna, style: {height: 22}},
  [PaymentMethods.IDEAL]: {src: ideal, style: {height: 21}},
  [PaymentMethods.GIROPAY]: {src: giropay, style: {height: 20}},
  [PaymentMethods.MBWAY]: {src: mbway, style: {height: 19}},
  [PaymentMethods.SOFORT]: {src: sofort, style: {height: 20}},
  [PaymentMethods.SEPA]: {src: sepa, style: {height: 14}},
  [PaymentMethods.TRUSTLY]: {src: trustly, style: {height: 16}},
  [PaymentMethods.BANCONTACT]: {src: bancontactSmall, style: {height: 22}},
  [PaymentMethods.EPS]: {src: eps, style: {height: 22}},
  [PaymentMethods.BLIK]: {src: blik, style: {height: 19}}
};

export const tokenizationMethodMinimalIcons: {[key: string]: any} = {
  [TokenizationMethods.GOOGLEPAY]: {src: googlePaySmall, style: {height: 18}},
  [TokenizationMethods.APPLEPAY]: {src: applePaySmall, style: {height: 18}},
  [TokenizationMethods.CLICKTOPAY]: {src: clickToPay, style: {height: 14, marginRight: 10}}
};

export const cardProcessorIcons: {[key: string]: any} = {
  [Processor.REDSYS]: redsys,
  [Processor.MONEI_REDSYS]: redsys,
  [Processor.ADYEN]: adyen,
  [Processor.STRIPE]: stripe,
  [Processor.MONEIX_F]: monei_x
};

export const paymentProcessorLabels: {[key: string]: any} = {
  [Processor.REDSYS]: 'Redsys',
  [Processor.ADYEN]: 'Adyen',
  [Processor.STRIPE]: 'Stripe',
  [Processor.MONEIX_F]: 'MONEI X Spain',
  [Processor.MONEIX_G]: 'MONEI X Global',
  [Processor.MONEIX_P]: 'MONEIX P',
  [Processor.PAYPAL]: 'PayPal',
  [Processor.MONEI_REDSYS]: 'MONEI Redsys'
};

export const acquirerLabels: {[key in Acquirer]: string} = {
  [Acquirer.MONEI_X]: 'MONEI X',
  [Acquirer.BANKIA]: 'Bankia',
  [Acquirer.KUTXABANK]: 'Kutxabank',
  [Acquirer.SANTANDER]: 'Santander',
  [Acquirer.STRIPE]: 'Stripe',
  [Acquirer.UNICAJA]: 'Unicaja',
  [Acquirer.PAYCOMET]: 'PAYCOMET',
  [Acquirer.SABADELL]: 'Banco Sabadell',
  [Acquirer.PAYPAL]: 'PayPal',
  [Acquirer.BBVA]: 'BBVA',
  [Acquirer.COMERCIAGLOBALPAY]: 'Comercia Global Payments',
  [Acquirer.CREDITANDORRA]: 'Credit Andorra',
  [Acquirer.BANKINTER]: 'Bankinter',
  [Acquirer.ADYEN]: 'Adyen',
  [Acquirer.CAJASUR]: 'CajaSur',
  [Acquirer.BANCAMARCH]: 'Banca March',
  [Acquirer.CAJAMAR]: 'Cajamar',
  [Acquirer.CAJARURAL]: 'Caja Rural',
  [Acquirer.CUSTOM]: "Merchant's acquirer",
  [Acquirer.CAJALABORAL]: 'Caja Laboral',
  [Acquirer.ABANCA]: 'Abanca',
  [Acquirer.COFIDIS]: 'Cofidis',
  [Acquirer.DEUTSCHE_BANK]: 'Deutsche Bank'
};

export const subscriptionStatusLabels: Record<SubscriptionStatus, string> = {
  [SubscriptionStatus.ACTIVE]: 'Active',
  [SubscriptionStatus.CANCELED]: 'Cancelled',
  [SubscriptionStatus.EXPIRED]: 'Expired',
  [SubscriptionStatus.PENDING]: 'Pending',
  [SubscriptionStatus.PAUSED]: 'Paused',
  [SubscriptionStatus.PAST_DUE]: 'Past due',
  [SubscriptionStatus.TRIALING]: 'In trial'
};

export const ThreeDSecureFlowStatus: Record<ThreeDSecureFlow, string> = {
  [ThreeDSecureFlow.FRICTIONLESS_CHALLENGE]: 'Frictionless and challenge',
  [ThreeDSecureFlow.CHALLENGE]: 'Challenge',
  [ThreeDSecureFlow.DIRECT]: 'Direct',
  [ThreeDSecureFlow.FRICTIONLESS]: 'Frictionless'
};

export const klarnaBillingCategory = (category: BillingCategoryKlarna, t: TFunction) => {
  const labels = {
    [BillingCategoryKlarna.PAY_LATER]: t('Pay Later'),
    [BillingCategoryKlarna.SLICE_IT]: t('Fair Financing'),
    [BillingCategoryKlarna.PAY_NOW]: t('Pay Now'),
    [BillingCategoryKlarna.SLICE_IT_BY_CARD]: t('Pay in 3')
  };
  return labels[category];
};
