import {Button, Modal} from 'antd';
import {AnchorLink} from 'components/AnchorLink';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {useMediaQuery} from '../hooks/useMediaQuery';

type Props = {
  url: string;
  onAccept?: () => void;
};

const IFrame = styled.iframe`
  width: 100%;
  height: 80vh;
  max-height: 1280px;
  border: 0;
  display: block;
  @media (max-width: 991px) {
    height: calc(100vh - 57px);
  }
`;

export const PolicyLink: React.FC<Props> = ({url, onAccept, children}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const {isMobile} = useMediaQuery();
  const {t} = useTranslation();
  const footer = [
    <Button key="cancel" onClick={() => setModalOpen(false)}>
      {onAccept ? t('Cancel') : t('Close')}
    </Button>
  ];
  if (onAccept) {
    footer.push(
      <Button
        key="submit"
        type="primary"
        onClick={() => {
          setModalOpen(false);
          onAccept?.();
        }}>
        {t('Accept')}
      </Button>
    );
  }

  if (isMobile) {
    return (
      <AnchorLink style={{whiteSpace: 'nowrap'}} onClick={() => window.open(url, '_blank')}>
        {children}
      </AnchorLink>
    );
  }

  return (
    <>
      <AnchorLink style={{whiteSpace: 'nowrap'}} onClick={() => setModalOpen(true)}>
        {children}
      </AnchorLink>
      <Modal
        width={800}
        wrapClassName="mobile-fullscreen"
        open={isModalOpen}
        destroyOnClose
        closable={false}
        title={false}
        onCancel={() => setModalOpen(false)}
        centered
        bodyStyle={{padding: 0}}
        footer={footer}>
        <IFrame src={url} />
      </Modal>
    </>
  );
};
