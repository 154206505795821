import {Card as $Card} from 'antd';
import styled, {css} from 'styled-components';

export const TableCard = styled($Card)<{loading: boolean}>`
  && .ant-table-pagination {
    margin-right: 20px;
  }
  && .ant-table-small {
    border: none;
  }
  && .ant-tabs-tab {
    padding: 16px;
  }
  ${({loading}) =>
    !loading &&
    css`
      && > .ant-card-body {
        padding: 0;
        margin-top: 1px;
      }
    `}
` as unknown as typeof $Card;
