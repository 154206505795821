import {LoadingOutlined} from '@ant-design/icons';
import {Typography} from 'antd';
import {TextProps} from 'antd/es/typography/Text';
import {Secret} from 'components/Secret';
import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

const {Text} = Typography;

export type Property = {
  label: any;
  value: any;
  hide?: boolean;
  secret?: boolean;
} & TextProps;

type Props = {
  properties: Array<Property>;
  omitEmpty?: boolean;
  loading?: boolean;
  labelWidth?: number;
  style?: React.CSSProperties;
};

const List = styled.ul`
  list-style: none;
`;
const ListItem = styled.li`
  display: flex;
  padding-top: 4px;
  padding-bottom: 4px;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Label = styled.div<{labelWidth: number}>`
  color: rgba(0, 0, 0, 0.55);
  flex: 0 0 50%;
  min-width: 180px;
  max-width: ${(props) => props.labelWidth}px;
`;
const Value = styled.div`
  color: rgba(0, 0, 0, 0.75);
  padding: 0 4px;
  @media (max-width: 768px) {
    padding: 4px 0;
  }
`;

export const PropertyList: React.FC<Props> = ({
  properties,
  omitEmpty = false,
  loading = false,
  labelWidth = 215,
  style
}) => {
  const {t} = useTranslation();
  const data = omitEmpty
    ? properties.filter((prop) => !prop.hide && ![null, undefined].includes(prop.value))
    : properties;

  const renderValue = ({label, value, secret, ...textProps}: Property) => {
    if (loading) {
      return (
        <Text type="secondary">
          <LoadingOutlined />
        </Text>
      );
    }
    if ([null, undefined].includes(value)) {
      return (
        <Text type="secondary">{t(`No {{label}} provided`, {label: label.toLowerCase()})}</Text>
      );
    }
    if (secret) {
      return <Secret {...textProps}>{value}</Secret>;
    }
    return <Text {...textProps}>{value}</Text>;
  };

  return (
    <List style={style}>
      {data.map((prop, i) => (
        <ListItem key={i}>
          <Label labelWidth={labelWidth}>{prop.label}</Label>
          <Value>{renderValue(prop)}</Value>
        </ListItem>
      ))}
    </List>
  );
};
