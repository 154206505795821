import {Switch} from 'antd';
import {useTestModeState} from 'hooks/useTestModeState';
import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

type Props = {};

const TestSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: #d77e45;
  }
  .ant-switch-inner {
    font-size: 0.7rem;
    white-space: nowrap;
  }
`;

export const TestModeSwitch: React.FC<Props> = () => {
  const {t} = useTranslation();
  const {isTestMode, setTestMode} = useTestModeState(null);
  return (
    <TestSwitch
      checkedChildren={t('Test data')}
      unCheckedChildren={t('Live data')}
      checked={Boolean(isTestMode)}
      onChange={(checked) => setTestMode(checked)}
    />
  );
};
