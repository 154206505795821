import {CardBrand} from 'api/generated';
import amex from 'payment-icons/min/flat/amex.svg';
import unknown from 'payment-icons/min/flat/default.svg';
import diners from 'payment-icons/min/flat/diners.svg';
import jcb from 'payment-icons/min/flat/jcb.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';
import unionpay from 'payment-icons/min/flat/unionpay.svg';
import discover from 'payment-icons/min/flat/discover.svg';
import visa from 'payment-icons/min/flat/visa.svg';

export const paymentBrandIcons: {[brand in CardBrand]: string} = {
  amex,
  diners,
  jcb,
  mastercard,
  unionpay,
  visa,
  discover,
  unknown
};
