import {TFunction} from 'i18next';
import {config} from 'stage.config';

export enum PolicyType {
  PRIVACY_POLICY = 'privacy_policy',
  TERMS_OF_SERVICE = 'terms_of_service',
  PRE_CONTRACT = 'pre_contract',
  BIOMETRIC_POLICY = 'biometric_policy'
}

export const getPolicies = (t: TFunction) => {
  return {
    [PolicyType.PRIVACY_POLICY]: {
      title: t('Privacy Policy'),
      doc: config.privacyPolicyDoc,
      cognitoDocField: 'custom:pp_doc',
      cognitoDateField: 'custom:pp_accepted_at'
    },
    [PolicyType.TERMS_OF_SERVICE]: {
      title: t('Legal Notice'),
      doc: config.termsOfServiceDoc,
      cognitoDocField: 'custom:tos_doc',
      cognitoDateField: 'custom:tos_accepted_at'
    },
    [PolicyType.PRE_CONTRACT]: {
      title: t('Pre-Contractual Information'),
      doc: config.preContractDoc,
      cognitoDocField: 'custom:pc_doc',
      cognitoDateField: 'custom:pc_accepted_at'
    },
    [PolicyType.BIOMETRIC_POLICY]: {
      title: t('Biometric Data Privacy Policy'),
      doc: config.biometricPolicyDoc,
      cognitoDocField: 'custom:bp_doc',
      cognitoDateField: 'custom:bp_accepted_at'
    }
  };
};

export const getPolicyUrl = (name: string, locale: string) => {
  locale = locale.split('-')[0];
  return `https://assets.monei.com/docs/${locale}/${name}`;
};
