import {Result as $Result} from 'antd';
import styled from 'styled-components';

export const Result = styled($Result)`
  @media (max-width: 1024px) {
    padding: 36px 0;
    .ant-result-icon > .anticon {
      font-size: 54px;
    }
  }
  @media (max-width: 600px) {
    padding: 24px 12px;
  }
  @media (max-width: 450px) {
    padding: 24px 0;
    .ant-result-icon > .anticon {
      font-size: 40px;
    }
    .ant-result-content {
      padding: 12px 20px;
    }
  }
`;
