import Big from 'big.js';
import dot from 'dot-object';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import {CountryCode} from 'libphonenumber-js/types';
import {divide, multiply, round} from 'mathjs';
import {NO_HOSTNAME, parseDomain, ParseResultType} from 'parse-domain';
import React, {ComponentType, LazyExoticComponent} from 'react';
import {ChargeFieldsFragment} from '../api/generated';

export const MEMO_ARRAY = [];

export function enumKeys<E extends object>(e: E): Array<keyof E> {
  return Object.keys(e) as Array<keyof E>;
}

export function enumValues<E extends object>(e: E): Array<E> {
  return Object.values(e) as Array<E>;
}

/**
 * Calculates percentage rate
 * @param num
 * @param denom
 */
export const getRate = (num = 0, denom = 1) => {
  if (denom === 0) return 0;
  const ratio = num / denom;
  return (ratio > 1 ? 1 : ratio) * 100;
};

/**
 * Calculates increase in percentages
 * @param first
 * @param second
 */
export const getIncrease = (first = 0, second = 1) => {
  if (first === 0 && second === 0) return 0;
  if (second === 0) return 100;
  const ratio = (first - second) / second;
  if (ratio > 100) return 100;
  return ratio * 100;
};

export interface ILazyComponent<T extends ComponentType<any>> extends LazyExoticComponent<T> {
  preload: () => Promise<any>;
}

/**
 * Lazy load component and add preload method
 * Call Component.preload() to fetch async chunks
 * @param factory
 */
export const lazyWithPreload = <T extends ComponentType<any>>(
  factory: () => Promise<{default: T}>
) => {
  const Component = React.lazy(factory) as ILazyComponent<T>;
  Component.preload = factory;
  return Component;
};

/**
 * Get random int in rage
 * @param min
 * @param max
 */
export const getRandomInt = (min: number, max: number) => {
  return Math.round(Math.random() * (max - min) + min);
};

/**
 *intersperse: Return an array with the separator interspersed between
 * each element of the input array.
 *
 * > intersperse([1,2,3], 0)
 * [1,0,2,0,3]
 */
export function intersperse(arr: any[], sep: any) {
  if (arr.length === 0) return [];
  return arr.slice(1).reduce((xs, x) => xs.concat([sep, x]), [arr[0]]);
}

/**
 * Checks if variable is undefined or null
 * @param variable
 */
export const isUndefined = (variable: any) => {
  return typeof variable === 'undefined' || variable === null;
};

/**
 * Capitalize firs letter
 * @param s - string
 */
export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const downloadTextAsFile = ({
  fileName,
  text,
  type
}: {
  fileName: string;
  text: string;
  type: string;
}) => {
  const element = document.createElement('a');
  element.setAttribute('href', `data:${type};charset=utf-8,${encodeURIComponent(text)}`);
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const downloadFile = (url: string, name?: string) => {
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('download', name ?? 'true');
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

/**
 * Returns zero if number is negative
 * @param n
 */
export const positiveOrZero = (n: number) => {
  return n > 0 ? n : 0;
};

/**
 * Makes sure that the object is of type object and is not null.
 */
export const isObject = (object: any) => object != null && typeof object === 'object';

export const pick = (obj: {[key: string]: any} | undefined, keys: string[]) => {
  const result: {[key: string]: any} = {};
  if (!obj) return result;
  keys.forEach((key) => {
    if (obj[key] === undefined) return;
    result[key] = obj[key];
  });
  return result;
};

export const unique = <T>(array: T[]) => {
  return Array.from(new Set(array));
};

export const omit = (obj: {[key: string]: any} | undefined, keys: string[]) => {
  const result: {[key: string]: any} = {};
  if (!obj) return result;
  Object.keys(obj).forEach((key) => {
    if (keys.includes(key)) return;
    result[key] = obj[key];
  });
  return result;
};

export const delay = (ms: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => resolve(), ms);
  });
};

export const getHostname = (url?: string | null) => {
  if (!url) return '';
  return /\/\/([^/]+)/.exec(url)?.[1] ?? '';
};

export const getTopDomain = (hostname: string | typeof NO_HOSTNAME) => {
  const result = parseDomain(hostname);
  if (result.type !== ParseResultType.Listed) return '';
  return [result.domain, ...result.topLevelDomains].join('.');
};

export function nullify<T = Record<any, any>>(o: T) {
  return Object.keys(o as any).reduce<T>((result, key) => {
    if (['', undefined].includes((o as any)[key])) {
      (result as any)[key] = null;
    } else if (Array.isArray((o as any)[key])) {
      (result as any)[key] = (o as any)[key].map(nullify);
    } else if (isObject((o as any)[key])) {
      (result as any)[key] = nullify((o as any)[key]);
    } else {
      (result as any)[key] = (o as any)[key];
    }
    return result;
  }, {} as T);
}

export const parseJSON = <T extends any>(json: string): T | null => {
  try {
    return JSON.parse(json);
  } catch (error) {
    return null;
  }
};

export const amountToInt = (amount: number) => {
  return Big(amount).times(100).toNumber();
};

export const amountFromInt = (amount: number) => {
  return Big(amount).div(100).toNumber();
};

export const arrayIncludesSome = (array: any[], values: any[]) => {
  return values.some((value) => array.includes(value));
};

export const isQrPayment = (charge: ChargeFieldsFragment) => {
  return !!charge.paymentMethod && charge.orderId?.startsWith('QR');
};
export const truncate = (str: string | null | undefined, length: number) => {
  if (!str) return '';
  if (str.length <= length) return str;
  return `${str.substring(0, length)}...`;
};

export const dateFormat = {
  dateTime: 'DD/MM/YY HH:mm',
  localizedShort: 'lll'
};

export const normalizePhone = (phone?: string | null, countryCode?: string | null) => {
  if (!phone) return null;
  return parsePhoneNumberFromString(phone, countryCode as CountryCode)?.format('E.164');
};

export const openPopup = (params: {
  url?: string;
  title?: string;
  width: number;
  height: number;
}): Window => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const left = (width - params.width) / 2 + dualScreenLeft;
  const top = (height - params.height) / 2 + dualScreenTop;
  const newWindow = window.open(
    params.url,
    params.title,
    `
      scrollbars=yes,
      width=${params.width}, 
      height=${params.height}, 
      top=${top}, 
      left=${left}
      `
  );

  if (!newWindow) {
    throw new Error('POPUP_BLOCKED');
  }
  if (newWindow.focus) newWindow.focus();
  return newWindow;
};

export const getChangedKeys = (obj1: any = {}, obj2: any = {}) => {
  const dotObj1 = dot.dot(obj1);
  const dotObj2 = dot.dot(obj2);
  const keys = unique(Object.keys(dotObj1).concat(Object.keys(dotObj2)));
  return keys.filter((key) => JSON.stringify(dotObj1[key]) !== JSON.stringify(dotObj2[key]));
};

export const isDefined = (
  value: any,
  options: {allowZero?: boolean; allowEmptyString?: boolean} = {
    allowZero: true,
    allowEmptyString: true
  }
) => {
  if (options.allowEmptyString && value === '') return true;
  if (options.allowZero && value === 0) return true;
  return Boolean(value);
};

export const getOffsetTop = (element: HTMLElement | null) => {
  let offsetTop = 0;
  while (element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent as HTMLElement;
  }
  return offsetTop;
};

export const scrollToError = (
  errorKey: string,
  parent: HTMLElement | Window = window,
  offset = 150
) => {
  let el = document.querySelector(`label[for="${errorKey}"]`);
  if (!el) {
    el = document.getElementsByName(errorKey)[0];
  }
  if (el) {
    const offsetTop = getOffsetTop(el as HTMLElement);
    parent.scrollTo({top: offsetTop - offset, behavior: 'smooth'});
  }
};

export const openNewTab = (url: string | undefined) => url && window.open(url, '_blank');

export const toCents = (amount: number | null = null) => {
  if (amount === null) return null;
  return round(multiply(amount, 100));
};

export const fromCents = (amount: number | null = null) => {
  if (amount === null) return null;
  return round(divide(amount, 100));
};

export const translateEnums = (enums: {[key: string]: string}, t: (value: string) => string) => {
  /* i18next-extract-disable-next-line */
  return Object.fromEntries(Object.entries(enums).map(([key, value]) => [key, t(value)]));
};

export const isMobileDisplay = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};
