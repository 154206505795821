import {IdcardOutlined, LockOutlined} from '@ant-design/icons/lib';
import {Button, Card} from 'antd';
import {Auth} from 'aws-amplify';
import {FormikProps, withFormik} from 'formik';
import {Form, FormItem, Input} from 'formik-antd';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {AuthState, IAuthProps} from 'types';
import * as yup from 'yup';
import {AnchorLink} from '../AnchorLink';
import {Extra} from './Shared';

interface IFormValues {
  email: string;
  code: string;
}

const C: React.FC<FormikProps<IFormValues> & IAuthProps> = ({
  isSubmitting,
  setSubmitting,
  setState,
  values,
  errors
}) => {
  const {t} = useTranslation();
  const resendCode = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSubmitting(true);
    await Auth.resendSignUp(values.email);
    setSubmitting(false);
  };
  const backToSignIn = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setState({authState: AuthState.signIn, email: values.email});
  };
  return (
    <Card
      title={t('Confirm Sign Up')}
      extra={<AnchorLink onClick={backToSignIn}>{t('Back to Sign In')}</AnchorLink>}>
      <Form>
        <FormItem name="email">
          <Input
            autoFocus
            name="email"
            type="email"
            size="large"
            disabled
            prefix={<IdcardOutlined />}
            placeholder={t('Email')}
          />
        </FormItem>
        <FormItem
          name="code"
          {...(errors.code ? {} : {help: null})}
          extra={
            <Trans>
              Check your email for a confirmation code and enter it here to complete registration.
            </Trans>
          }>
          <Input
            name="code"
            type="text"
            size="large"
            autoComplete="one-time-code"
            prefix={<LockOutlined />}
            placeholder={t('Confirmation code')}
          />
        </FormItem>
        <Button block size="large" type="primary" loading={isSubmitting} htmlType="submit">
          {t('Confirm')}
        </Button>
        <Extra>
          <Trans key="Lost your code?">
            Lost your code? <AnchorLink onClick={resendCode}>Resend Code</AnchorLink>
          </Trans>
        </Extra>
      </Form>
    </Card>
  );
};

export const ConfirmSignUp = withFormik<IAuthProps, IFormValues>({
  validationSchema: () =>
    yup.object().shape({
      email: yup.string().email().required(),
      code: yup.string().required()
    }),
  mapPropsToValues: ({email = '', code = ''}) => ({email, code}),
  handleSubmit: async ({email, code}, {props, setErrors, setSubmitting}) => {
    try {
      await Auth.confirmSignUp(email, code);
      // sign in user after email is confirmed
      await Auth.signIn(email, props.password);
      // get current user, CognitoUser returned form Auth.signIn does not have attributes
      const currentUser = await Auth.currentAuthenticatedUser();
      props.setState({authState: AuthState.signedUp, currentUser});
    } catch (error: any) {
      setErrors({code: error.message});
    } finally {
      setSubmitting(false);
    }
  }
})(C);
