/* tslint:disable:react-hooks-nesting */
import {PlusOutlined} from '@ant-design/icons';
import {message} from 'antd';
import {Auth} from 'aws-amplify';
import {SignUpExtra} from 'components/Auth/SignUpExtra';
import {getUserAttributes} from 'components/Auth/utils';
import bg from 'images/bg.svg';
import {MainFooter} from 'layout/MainFooter';
import {
  confirmSignInEvent,
  confirmSignUpEvent,
  showSignInEvent,
  showSignUpEvent,
  signedInEvent,
  signedUpEvent
} from 'lib/gtmEvents';
import {setFormikLocale} from 'lib/setFormikLocale';
import {UserContext} from 'lib/userContext';
import {ILazyComponent} from 'lib/utils';
import qs from 'query-string';
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {AuthState, IAuthProps, IAuthQueryParams, IAuthState} from 'types';
import {StringParam, useQueryParam} from 'use-query-params';
import {GlobalStyle} from '../../global.styles';
import {useMediaQuery} from '../../hooks/useMediaQuery';
import {mobileViewport} from '../../layout/constants';
import {usePartners} from '../../lib/partners';
import {hideZendeskWidget, logoutZendeskWidget} from '../../lib/zendesk';
import {Spinner} from '../Spinner';
import {Combined} from './Combined';
import {ConfirmSignIn} from './ConfirmSignIn';
import {ConfirmSignUp} from './ConfirmSignUp';
import {ForgotPassword} from './ForgotPassword';
import {ForgotPasswordSubmit} from './ForgotPasswordSubmit';
import {RequireNewPassword} from './RequireNewPassword';
import {Logo} from './Shared';

const Layout = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 50px 0 100px;
  height: 100%;
  background: url(${bg}) no-repeat 0 0;
  background-size: 100%;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
`;

const Content = styled.div`
  width: 450px;
  margin: 0 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ant-input-prefix {
    color: rgba(0, 0, 0, 0.25) !important;
  }

  .ant-card {
    width: 100%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  }

  .ant-card-head {
    padding: 0 40px;
    @media (max-width: 600px) {
      padding: 0 25px;
    }
  }

  .ant-card-body {
    padding: 40px 40px;
    @media (max-width: 600px) {
      padding: 25px 25px;
    }
  }

  @media (max-width: 600px) {
    width: calc(100% - 40px);
    margin: 0 20px 50px;
  }
`;

const PartnerLogo = styled.a`
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: calc(100% - 40px);
  img {
    max-width: 100%;
  }
`;

const PlusIcon = styled(PlusOutlined)`
  margin: 15px 0;
  font-size: 25px;
  color: #8a8a8a;
`;

const LogoWithPartner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

  ${Logo} {
    margin-bottom: 0;
  }
`;

const initialState: IAuthState = {
  authState: AuthState.init
};

const allowedActions = [
  AuthState.signIn,
  AuthState.signUp,
  AuthState.forgotPassword,
  AuthState.forgotPasswordSubmit,
  AuthState.confirmSignUp
];

export const withAuthenticator = (Cmp: ILazyComponent<any>) => {
  return () => {
    const {t} = useTranslation();
    const [state, setState] = useState<IAuthState>(initialState);
    const [action, setAction] = useQueryParam('action', StringParam);
    const {isTablet} = useMediaQuery();
    const [partner] = usePartners();
    const history = useHistory();
    const location = useLocation();

    setFormikLocale(t);

    const setAuthState = (state: IAuthState) => {
      if (state.authState === AuthState.signedIn) {
        const {promo, h, mid} = qs.parse(location.search) as IAuthQueryParams;
        if (promo) {
          const query = qs.stringify({promo, h, mid});
          history.replace(`/connect?${query}`);
        } else {
          history.replace(location.pathname);
        }
      }
      setState(state);
    };

    const handleSignOut = async (state: IAuthState = {authState: AuthState.signIn}) => {
      await Auth.signOut();
      logoutZendeskWidget();
      history.replace(location.pathname);
      setState(state);
    };

    useEffect(() => {
      switch (state.authState) {
        case AuthState.signUp:
          showSignUpEvent();
          break;
        case AuthState.signIn:
          showSignInEvent();
          break;
        case AuthState.signedIn: {
          if (!state.currentUser) break;
          const {accountId, userEmail} = getUserAttributes(state.currentUser);
          signedInEvent(accountId, userEmail);
          break;
        }
        case AuthState.signedUp: {
          if (!state.currentUser) break;
          const {accountId, userEmail} = getUserAttributes(state.currentUser);
          signedUpEvent(accountId, userEmail);
          break;
        }
        case AuthState.confirmSignUp:
          confirmSignUpEvent(state.email!);
          break;
        case AuthState.confirmSignIn:
          confirmSignInEvent(state.email!);
          break;
      }
    }, [state.authState]);

    useEffect(() => {
      hideZendeskWidget();

      const directAuthState = ({
        email,
        password,
        code,
        action,
        ref,
        amazonCustomerId,
        promo,
        mid,
        h
      }: IAuthQueryParams) => {
        setAuthState({
          authState: action as AuthState,
          email,
          password,
          code,
          amazonCustomerId,
          referralCode: ref,
          promoCode: promo,
          externalId: mid,
          externalHash: h
        });
      };

      const directSignIn = async (email: string, password: string) => {
        try {
          const user = await Auth.signIn(email, password);
          if (user.challengeName && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            return setAuthState({
              authState: AuthState.requireNewPassword,
              currentUser: user,
              email
            });
          }
          return await checkAuth();
        } catch (error: any) {
          if (error.code === 'UserNotConfirmedException') {
            return setAuthState({authState: AuthState.confirmSignUp, email, password});
          }
          message.error(error.message);
          setAuthState({authState: AuthState.signIn, email});
        } finally {
          history.replace(location.pathname);
        }
      };

      const directConfirmation = async (email: string, code: string) => {
        try {
          await Auth.confirmSignUp(email, code);
          message.success('Your account is confirmed, please sign in');
        } catch (error: any) {
          message.error(error.message);
        } finally {
          history.replace(location.pathname);
          setAuthState({authState: AuthState.signIn, email});
        }
      };

      const checkAuth = async () => {
        try {
          const currentUser = await Auth.currentAuthenticatedUser({bypassCache: true});
          setState({authState: AuthState.signedIn, currentUser});
        } catch {
          setAuthState({authState: AuthState.signIn});
        }
      };

      const {email, password, code, action, amazonCustomerId, ref, promo, utm_campaign, mid, h} =
        qs.parse(location.search) as IAuthQueryParams;

      if (allowedActions.includes(action as AuthState)) {
        if (action === AuthState.signIn && email && password) {
          directSignIn(email, password);
        } else {
          directAuthState({
            email,
            password,
            code,
            action,
            amazonCustomerId,
            ref,
            mid,
            h,
            promo: promo || utm_campaign
          });
        }
      } else if (email && code) {
        directConfirmation(email, code);
      } else {
        checkAuth();
      }
      Cmp.preload().then(() => {
        console.log('App is loaded');
      });
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (action !== state.authState && allowedActions.includes(state.authState)) {
        setAction(state.authState);
      }
      // eslint-disable-next-line
    }, [setAction, state.authState]);

    useEffect(() => {
      if (action !== state.authState && allowedActions.includes(action as AuthState)) {
        setState((oldState) => ({...oldState, authState: action as AuthState}));
      }
      // eslint-disable-next-line
    }, [action]);

    const renderAuthView = () => {
      const props: IAuthProps = {...state, setState: setAuthState, t};
      switch (state.authState) {
        case AuthState.confirmSignUp:
          return <ConfirmSignUp {...props} />;
        case AuthState.confirmSignIn:
          return <ConfirmSignIn {...props} />;
        case AuthState.forgotPassword:
          return <ForgotPassword {...props} />;
        case AuthState.forgotPasswordSubmit:
          return <ForgotPasswordSubmit {...props} />;
        case AuthState.requireNewPassword:
          return <RequireNewPassword {...props} />;
        default:
          return <Combined {...props} />;
      }
    };

    if (state.authState === AuthState.init) return <Spinner />;
    if ([AuthState.signedIn, AuthState.signedUp].includes(state.authState)) {
      const currentUser = state.currentUser!;
      const isNew = state.authState === AuthState.signedUp;
      return (
        <UserContext.Provider
          value={{
            onSignOut: handleSignOut,
            setCurrentUser: (user) => setState((state) => ({...state, currentUser: user})),
            ...getUserAttributes(currentUser),
            isNew
          }}>
          <Cmp />
        </UserContext.Provider>
      );
    }
    const isSignUp = state.authState === AuthState.signUp;

    return (
      <Layout>
        <Helmet>
          <meta name="viewport" content={mobileViewport} />
          <link rel="preload" as="image" href={bg} />
        </Helmet>
        <GlobalStyle />
        <Wrapper>
          <Container>
            {isSignUp && !isTablet && <SignUpExtra />}
            <Content>
              {isSignUp ? (
                isTablet ? (
                  partner ? (
                    <LogoWithPartner>
                      <Logo src="https://assets.monei.com/images/logo.svg" />
                      <PlusIcon />
                      <PartnerLogo href={partner.url} target="_blank" title={partner.name}>
                        <img src={partner.logo} alt={partner.name} style={partner.logoStyle} />
                      </PartnerLogo>
                    </LogoWithPartner>
                  ) : (
                    <Logo src="https://assets.monei.com/images/logo.svg" />
                  )
                ) : (
                  <div style={{height: 75}} />
                )
              ) : (
                <Logo src="https://assets.monei.com/images/logo.svg" />
              )}
              {renderAuthView()}
            </Content>
          </Container>
        </Wrapper>
        <MainFooter />
      </Layout>
    );
  };
};
