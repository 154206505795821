type Stage = 'dev' | 'prod';

const stage = (process.env.REACT_APP_STAGE as Stage) || 'dev';

const globalConfig = {
  termsOfServiceUrl: 'https://monei.com/terms-of-service/',
  privacyPolicyUrl: 'https://monei.com/privacy-policy/',
  termsOfServiceDoc: 'terms-of-service-v3.pdf',
  privacyPolicyDoc: 'privacy-policy-v1.pdf',
  preContractDoc: 'pre-contract-info-v2.pdf',
  biometricPolicyDoc: 'biometric-data-policy-v1.pdf',
  testingUrl: 'https://docs.monei.com/docs/testing/',
  supportEmail: 'support@monei.com',
  supportUrl: 'https://support.monei.com',
  supportFormUrl: 'https://support.monei.com/hc/requests/new',
  docsUrl: 'https://docs.monei.com',
  supportedLanguages: ['en', 'es', 'ca', 'pt', 'de', 'it', 'fr']
};

const stageConfig = {
  dev: {
    production: false,
    region: 'eu-west-1',
    apiEndpoint: 'https://iye6jsnmozda3epe2tqfmapcri.appsync-api.eu-west-1.amazonaws.com/graphql',
    graphqlEndpoint: 'https://mo-graphql.microapps-staging.com',
    identityPoolId: 'eu-west-1:f473b883-ee13-481f-98ce-faedf4b1c769',
    userPoolId: 'eu-west-1_4DJSoUfPN',
    userPoolWebClientId: '4s0c3gnha7jievbgahiqtsq3np',
    gtmId: undefined,
    gtmAuth: undefined,
    gtmPreview: undefined,
    measurementId: undefined,
    recaptchaSiteKey: '6LejBMYUAAAAAIqU0f3BtAMzBqy70HIIbUuKjqvT',
    serviceBucket: 'monei-dev-service-bucket',
    paymentPagePreviewUrl: 'https://mo-js.microapps-staging.com/v2/payment-page/',
    hostedPaymentDomain: 'secure.microapps-staging.com',
    mobileAppUrl: 'https://m-pay.microapps-staging.com',
    maintenanceMode: false,
    restApiEndpoint: 'https://api.microapps-staging.com',
    pinpointApi: 'https://7isy4yk20g.execute-api.eu-west-1.amazonaws.com/dev/',
    checkCountriesEndpoint: 'https://api.microapps-staging.com/supported-countries'
  },
  prod: {
    production: true,
    region: 'eu-west-1',
    apiEndpoint: 'https://tg2prshv7fa7fa6wxrw7yufnpu.appsync-api.eu-west-1.amazonaws.com/graphql',
    graphqlEndpoint: 'https://graphql.monei.com',
    identityPoolId: 'eu-west-1:9a97e4d1-4b73-4fb9-9c41-2a916450751f',
    userPoolId: 'eu-west-1_H1gXoXZt8',
    userPoolWebClientId: '7l2vgjv8eprl4fbbcmq2lirg9c',
    gtmId: 'GTM-NHS56QJ',
    gtmAuth: undefined,
    gtmPreview: undefined,
    measurementId: 'G-3P1JQLMQKG',
    recaptchaSiteKey: '6LejBMYUAAAAAIqU0f3BtAMzBqy70HIIbUuKjqvT',
    serviceBucket: 'monei-prod-service-bucket',
    paymentPagePreviewUrl: 'https://js.monei.com/v2/payment-page/',
    hostedPaymentDomain: 'secure.monei.com',
    mobileAppUrl: 'https://pay.monei.com',
    maintenanceMode: false,
    restApiEndpoint: 'https://api.monei.com',
    pinpointApi: 'https://pinpoint.monei.com/',
    checkCountriesEndpoint: 'https://api.monei.com/supported-countries'
  }
};

export const config = {...globalConfig, ...stageConfig[stage]};
