import {LockOutlined} from '@ant-design/icons/lib';
import {Button, Card} from 'antd';
import {Auth} from 'aws-amplify';
import {FormikProps, withFormik} from 'formik';
import {Form, FormItem, Input} from 'formik-antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {AuthState, IAuthProps} from 'types';
import * as yup from 'yup';
import {AnchorLink} from '../AnchorLink';

interface IFormValues {
  password: string;
  passwordConfirm: string;
}

const C: React.FC<FormikProps<IFormValues> & IAuthProps> = ({isSubmitting, setState, email}) => {
  const {t} = useTranslation();
  const backToSignIn = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setState({authState: AuthState.signIn, email});
  };
  return (
    <Card
      title={t('Set a new password')}
      extra={<AnchorLink onClick={backToSignIn}>{t('Back to Sign In')}</AnchorLink>}>
      <Form>
        <FormItem name="password">
          <Input.Password
            autoFocus
            name="password"
            type="password"
            size="large"
            prefix={<LockOutlined />}
            placeholder={t('Password')}
          />
        </FormItem>
        <FormItem name="passwordConfirm">
          <Input.Password
            name="passwordConfirm"
            type="password"
            size="large"
            prefix={<LockOutlined />}
            placeholder={t('Password confirmation')}
          />
        </FormItem>
        <Button block size="large" type="primary" loading={isSubmitting} htmlType="submit">
          {t('Change password')}
        </Button>
      </Form>
    </Card>
  );
};

export const RequireNewPassword = withFormik<IAuthProps, IFormValues>({
  validationSchema: ({t}: IAuthProps) =>
    yup.object().shape({
      password: yup.string().min(6).required(),
      passwordConfirm: yup
        .string()
        .oneOf([yup.ref('password')], t("passwords don't match"))
        .required(t('password confirmation is required'))
    }),
  mapPropsToValues: ({password = ''}) => ({password, passwordConfirm: ''}),
  handleSubmit: async ({password}, {props, setErrors, setSubmitting}) => {
    console.log(props.email);
    try {
      await Auth.completeNewPassword(
        props.currentUser,
        password,
        props.currentUser!.challengeParam!.requiredAttributes
      );
      props.setState({authState: AuthState.signIn, email: props.email});
    } catch (error: any) {
      if (error.code === 'CodeMismatchException') {
        return setErrors({password: error.message});
      }
      setErrors({password: error.message});
    } finally {
      setSubmitting(false);
    }
  }
})(C);
