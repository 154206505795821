import {useField} from 'formik';
import {Input} from 'formik-antd';
// @ts-ignore
import ColorPicker from 'rc-color-picker';
import 'rc-color-picker/dist/rc-color-picker.css';
import React from 'react';
import styled from 'styled-components';

type Props = {
  name: string;
};

const StyledInput = styled(Input)`
  max-width: 140px;
  .ant-input-group-addon {
    padding: 0;
  }

  .rc-color-picker-trigger {
    box-shadow: 0 0 0 4px #fff inset;
    padding: 0;
    border-radius: 0;
    border: 0;
  }
  .rc-color-picker-trigger,
  .rc-color-picker-wrap {
    display: block;
    width: 34px;
    height: 34px;
  }
`;

export const ColorInput: React.FC<Props> = ({name}) => {
  const [field, S, {setValue}] = useField(name);
  const handleChange = (result: {color: string; open: boolean}) => {
    setValue(result.color);
  };

  return (
    <StyledInput
      addonBefore={
        <ColorPicker color={field.value} onChange={handleChange} placement="bottomLeft" />
      }
      name={name}
    />
  );
};
