import {IdcardOutlined} from '@ant-design/icons/lib';
import {Button, Card} from 'antd';
import {Auth} from 'aws-amplify';
import {FormikProps, withFormik} from 'formik';
import {Form, FormItem, Input} from 'formik-antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {AuthState, IAuthProps} from 'types';
import * as yup from 'yup';
import {AnchorLink} from '../AnchorLink';
import {Extra} from './Shared';

interface IFormValues {
  email: string;
}

const C: React.FC<FormikProps<IFormValues> & IAuthProps> = ({isSubmitting, setState, values}) => {
  const {t} = useTranslation();
  const backToSignIn = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setState({authState: AuthState.signIn, email: values.email});
  };
  const goToSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setState({authState: AuthState.forgotPasswordSubmit});
  };
  return (
    <Card
      title={t('Reset your password')}
      extra={<AnchorLink onClick={backToSignIn}>{t('Back to Sign In')}</AnchorLink>}>
      <Form>
        <FormItem name="email">
          <Input
            autoFocus
            name="email"
            type="email"
            size="large"
            prefix={<IdcardOutlined />}
            placeholder={t('Email')}
          />
        </FormItem>
        <Button block size="large" type="primary" loading={isSubmitting} htmlType="submit">
          {t('Send code')}
        </Button>
        <Extra>
          <AnchorLink onClick={goToSubmit}>{t('I already have a code')}</AnchorLink>
        </Extra>
      </Form>
    </Card>
  );
};

export const ForgotPassword = withFormik<IAuthProps, IFormValues>({
  validationSchema: () =>
    yup.object().shape({
      email: yup.string().email().required()
    }),
  mapPropsToValues: ({email = ''}) => ({email}),
  handleSubmit: async ({email}, {props: {t, setState}, setErrors, setSubmitting}) => {
    try {
      await Auth.forgotPassword(email);
      setState({authState: AuthState.forgotPasswordSubmit, email});
    } catch (error: any) {
      if (error.code === 'UserNotFoundException') {
        return setErrors({email: t('Email does not exist')});
      }
      setErrors({email: error.message});
    } finally {
      setSubmitting(false);
    }
  }
})(C);
