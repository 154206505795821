import {withAuthenticator} from 'components/Auth';
import {lazyWithPreload} from 'lib/utils';
import React, {Suspense} from 'react';
import {Spinner} from 'components';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {QueryParamProvider} from 'use-query-params';

const LazyApp = lazyWithPreload(() => import(/* webpackChunkName: "app" */ './App'));

const App = withAuthenticator(LazyApp);

const Root = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <App />
        </QueryParamProvider>
      </Router>
    </Suspense>
  );
};

export default Root;
