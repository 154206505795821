import {CognitoUser} from 'amazon-cognito-identity-js';
import {RcFile} from 'antd/lib/upload/interface';
import {FetchResult} from 'apollo-link';
import {TFunction} from 'i18next';

export interface IUser extends CognitoUser {
  attributes: {
    'custom:account_id': string;
    'custom:promo_code'?: string;
    'custom:pp_doc'?: string;
    'custom:pp_accepted_at'?: string;
    'custom:tos_doc'?: string;
    'custom:tos_accepted_at'?: string;
    'custom:pc_doc'?: string;
    'custom:pc_accepted_at'?: string;
    'custom:bp_doc'?: string;
    'custom:bp_accepted_at'?: string;
    'custom:store_id'?: string;
    email: string;
    email_verified: boolean;
    phone_number: string;
    phone_number_verified: boolean;
    sub: string;
  };

  [key: string]: any;
}

export interface IS3Object {
  bucket: string;
  region: string;
  key: string;
  mimeType: string;
  localUri: File | RcFile;
}

export type MutateFunction<Mutation, MutationVariables> = (
  variables: MutationVariables
) => Promise<FetchResult<Mutation>>;

export enum AuthState {
  signIn = 'signIn',
  signUp = 'signUp',
  signedIn = 'signedIn',
  signedUp = 'signedUp',
  confirmSignUp = 'confirmSignUp',
  confirmSignIn = 'confirmSignIn',
  forgotPassword = 'forgotPassword',
  forgotPasswordSubmit = 'forgotPasswordSubmit',
  requireNewPassword = 'requireNewPassword',
  init = 'init'
}

export enum MfaType {
  SMS_MFA = 'SMS_MFA',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA'
}

export type IAuthState = {
  authState: AuthState;
  currentUser?: IUser;
  email?: string;
  code?: string;
  password?: string;
  mfaDestination?: string;
  mfaType?: MfaType;
  amazonCustomerId?: string;
  externalId?: string;
  externalHash?: string;
  referralCode?: string;
  promoCode?: string;
  skipNewsletter?: boolean;
};

export interface IAuthQueryParams {
  email?: string;
  password?: string;
  code?: string;
  action?: string;
  amazonCustomerId?: string;
  ref?: string;
  promo?: string;
  mid?: string;
  h?: string;
  from_mobile?: string;
  utm_campaign?: string;
}

export interface IAuthProps extends IAuthState {
  t: TFunction;

  setState(state: IAuthState): void;
}

export enum EventType {
  CHARGE_PENDING = 'charge.pending',
  CHARGE_PENDING_PROCESSING = 'charge.pending_processing',
  CHARGE_UPDATED = 'charge.updated',
  CHARGE_AUTHORIZED = 'charge.authorized',
  CHARGE_REFUNDED = 'charge.refunded',
  CHARGE_PARTIALLY_REFUNDED = 'charge.partially_refunded',
  CHARGE_CAPTURED = 'charge.captured',
  CHARGE_CANCELED = 'charge.canceled',
  CHARGE_SUCCEEDED = 'charge.succeeded',
  CHARGE_FAILED = 'charge.failed',
  CHARGE_EXPIRED = 'charge.expired',
  CHARGE_CHARGEBACK = 'charge.chargeback',
  CHARGE_PAID_OUT = 'charge.paid_out',
  SETTLEMENT_COMPLETED = 'settlement.completed',
  SETTLEMENT_PENDING = 'settlement.pending',
  SETTLEMENT_SUSPENDED = 'settlement.suspended',
  SUBSCRIPTION_PENDING = 'subscription.pending',
  SUBSCRIPTION_EXPIRED = 'subscription.expired',
  SUBSCRIPTION_TRIALING = 'subscription.trialing',
  SUBSCRIPTION_ACTIVATED = 'subscription.activated',
  SUBSCRIPTION_CANCELED = 'subscription.canceled',
  SUBSCRIPTION_PAUSED = 'subscription.paused',
  SUBSCRIPTION_PAST_DUE = 'subscription.past_due',
  SUBSCRIPTION_UPDATED = 'subscription.updated',
  ACCOUNT_INVOICE_PAID = 'account_invoice.paid',
  ACCOUNT_INVOICE_UNPAID = 'account_invoice.unpaid',
  ACCOUNT_INVOICE_PENDING = 'account_invoice.pending',
  ACCOUNT_INVOICE_PAST_DUE = 'account_invoice.past_due'
}

export enum SubscriptionKeysUpdate {
  PAUSE_INTERVAL_COUNT = 'pauseIntervalCount',
  PAUSED_AT_PERIOD_END = 'pauseAtPeriodEnd',
  CANCELED_AT_PERIOD_END = 'cancelAtPeriodEnd',
  AMOUNT = 'amount',
  INTERVAL = 'interval',
  INTERVAL_COUNT = 'intervalcount',
  CUSTOMER_EMAIL = 'customer.email',
  CUSTOMER_NAME = 'customer.name',
  CUSTOMER_PHONE = 'customer.phone',
  BILLING_DETAILS_EMAIL = 'billingDetails.email',
  BILLING_DETAILS_NAME = 'billingDetails.name',
  BILLING_DETAILS_COMPANY = 'billingDetails.company',
  BILLING_DETAILS_PHONE = 'billingDetails.phone',
  BILLING_DETAILS_ADDRESS_CITY = 'billingDetails.address.city',
  BILLING_DETAILS_ADDRESS_COUNTRY = 'billingDetails.address.country',
  BILLING_DETAILS_ADDRESS_LINE1 = 'billingDetails.address.line1',
  BILLING_DETAILS_ADDRESS_LINE2 = 'billingDetails.address.line2',
  BILLING_DETAILS_ADDRESS_ZIP = 'billingDetails.address.zip',
  BILLING_DETAILS_ADDRESS_STATE = 'billingDetails.address.state',
  SHIPPING_DETAILS_EMAIL = 'shippingDetails.email',
  SHIPPING_DETAILS_NAME = 'shippingDetails.name',
  SHIPPING_DETAILS_COMPANY = 'shippingDetails.company',
  SHIPPING_DETAILS_PHONE = 'shippingDetails.phone',
  SHIPPING_DETAILS_ADDRESS_CITY = 'shippingDetails.address.city',
  SHIPPING_DETAILS_ADDRESS_COUNTRY = 'shippingDetails.address.country',
  SHIPPING_DETAILS_ADDRESS_LINE1 = 'shippingDetails.address.line1',
  SHIPPING_DETAILS_ADDRESS_LINE2 = 'shippingDetails.address.line2',
  SHIPPING_DETAILS_ADDRESS_ZIP = 'shippingDetails.address.zip',
  SHIPPING_DETAILS_ADDRESS_STATE = 'shippingDetails.address.state'
}

export type LabelValue = {value: string; label: string};

export type NonEmptyRecord<T> = {[P in keyof T]-?: NonEmptyRecord<NonNullable<T[P]>>};
