import {IdcardOutlined, LockOutlined} from '@ant-design/icons/lib';
import {Button, Modal, Space, Typography} from 'antd';
import {Auth} from 'aws-amplify';
import {PolicyLink} from 'components/PolicyLink';
import {FormikProps, withFormik} from 'formik';
import {Checkbox, Form, FormItem, Input} from 'formik-antd';
import {getClientId} from 'lib/gtmEvents';
import {getPolicies, getPolicyUrl, PolicyType} from 'lib/policyUtils';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {Trans, useTranslation} from 'react-i18next';
import {config} from 'stage.config';
import styled from 'styled-components';
import {AuthState, IAuthProps} from 'types';
import * as yup from 'yup';
import {usePartners} from '../../lib/partners';
import {AnchorLink} from '../AnchorLink';
import {Extra} from './Shared';
import {normalizeErrorMessage} from './utils';
import {handle} from 'i18next-http-middleware';
import CheckCountryModal from './CheckCountryModal';

const {Paragraph} = Typography;

type FormValues = {
  email: string;
  password: string;
  passwordConfirm: string;
  captcha: string;
  locale: string;
  referralCode?: string;
  promoCode?: string;
  amazonCustomerId?: string;
  externalId?: string;
  externalHash?: string;
  privacyPolicyAccepted: boolean;
  privacyPolicyAcceptedAt?: string;
  termsOfServiceAccepted: boolean;
  termsOfServiceAcceptedAt?: string;
  preContractAccepted: boolean;
  preContractAcceptedAt?: string;
};

type UserAttributes = {
  email: string;
  locale: string;
  'custom:amazon_customer_id'?: string;
  'custom:external_id'?: string;
  'custom:referral_code'?: string;
  'custom:promo_code'?: string;
  'custom:cid'?: string;
  'custom:pp_doc'?: string;
  'custom:pp_accepted_at'?: string;
  'custom:tos_doc'?: string;
  'custom:tos_accepted_at'?: string;
  'custom:pc_doc'?: string;
  'custom:pc_accepted_at'?: string;
  'custom:bp_doc'?: string;
  'custom:bp_accepted_at'?: string;
};

const StyledFormItem = styled(FormItem)`
  & .ant-form-item-control-input {
    min-height: auto;
  }
`;

const C: React.FC<FormikProps<FormValues> & IAuthProps> = ({
  isSubmitting,
  setSubmitting,
  setFieldValue,
  handleSubmit,
  errors
}) => {
  const [checkingCountry, setCheckingCountry] = useState(true);
  const captchaRef = useRef<any>(null);
  const [partner] = usePartners();
  const {t, i18n} = useTranslation();
  const policies = getPolicies(t);

  useEffect(() => {
    if (!captchaRef.current) return;
    setTimeout(() => {
      // @ts-ignore
      const container: HTMLDivElement = captchaRef.current.captcha;
      const iframe = container.getElementsByTagName('iframe')[0];
      if (!iframe) return;
      iframe.tabIndex = -1;
    }, 500);
  }, []);

  const handlePolicyUpdate = useCallback(
    (name: string, isAccepted: boolean) => {
      setFieldValue(`${name}At`, new Date().toISOString());
      setFieldValue(name, isAccepted);
    },
    [setFieldValue]
  );

  const renderPolicyCheckbox = useCallback(
    (name: string, policyType: PolicyType) => {
      const policy = policies[policyType];
      const policyUrl = getPolicyUrl(policy.doc, i18n.resolvedLanguage!);
      return (
        <StyledFormItem name={name}>
          <Space align="start" size="middle">
            <Checkbox
              name={name}
              id={name}
              onChange={(e) => handlePolicyUpdate(name, e.target.checked)}
            />
            <label htmlFor={name}>
              <Trans>
                I confirm that I have read and accept the{' '}
                <PolicyLink url={policyUrl} onAccept={() => handlePolicyUpdate(name, true)}>
                  {{label: policy.title}}
                </PolicyLink>
              </Trans>
            </label>
          </Space>
        </StyledFormItem>
      );
    },
    [handlePolicyUpdate, i18n.resolvedLanguage, policies]
  );

  return (
    <>
      <CheckCountryModal onCheckingCountryChange={(value: boolean) => setCheckingCountry(value)} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!captchaRef.current) return;
          captchaRef.current.execute();
        }}>
        <FormItem name="email">
          <Input
            autoFocus
            name="email"
            type="email"
            autoComplete="email"
            size="large"
            onChange={(e) => {
              setFieldValue('email', e.target.value.toLowerCase());
            }}
            prefix={<IdcardOutlined />}
            placeholder={t('Email')}
          />
        </FormItem>
        <FormItem name="password">
          <Input.Password
            name="password"
            type="password"
            autoComplete="new-password"
            size="large"
            prefix={<LockOutlined />}
            placeholder={t('Password')}
          />
        </FormItem>
        <ReCAPTCHA
          ref={captchaRef}
          sitekey={config.recaptchaSiteKey}
          tabindex={-1}
          size="invisible"
          onErrored={() => {
            setSubmitting(false);
          }}
          onExpired={() => {
            setSubmitting(false);
          }}
          onChange={(captcha) => {
            setFieldValue('captcha', captcha);
            setFieldValue('locale', i18n.resolvedLanguage);
            handleSubmit();
            captchaRef?.current?.reset();
          }}
        />
        <FormItem name="passwordConfirm">
          <Input.Password
            name="passwordConfirm"
            type="password"
            autoComplete="new-password"
            size="large"
            prefix={<LockOutlined />}
            placeholder={t('Password confirmation')}
          />
        </FormItem>
        {renderPolicyCheckbox('privacyPolicyAccepted', PolicyType.PRIVACY_POLICY)}
        {renderPolicyCheckbox('termsOfServiceAccepted', PolicyType.TERMS_OF_SERVICE)}
        {partner?.code !== 'comercia' &&
          renderPolicyCheckbox('preContractAccepted', PolicyType.PRE_CONTRACT)}
        <Button
          block
          size="large"
          type="primary"
          htmlType="submit"
          disabled={checkingCountry}
          loading={isSubmitting}>
          {t('Sign Up')}
        </Button>
        <Extra>
          <Paragraph type="secondary">
            <Trans>
              You'll receive emails about product and feature updates. Unsubscribe any time.
            </Trans>
          </Paragraph>
          <Paragraph type="secondary" style={{marginBottom: 0}}>
            <Trans>
              This site is protected by reCAPTCHA and the Google{' '}
              <AnchorLink target="_blank" href="https://policies.google.com/privacy">
                Privacy Policy
              </AnchorLink>{' '}
              and{' '}
              <AnchorLink target="_blank" href="https://policies.google.com/terms">
                Terms of Service
              </AnchorLink>{' '}
              apply.
            </Trans>
          </Paragraph>
        </Extra>
      </form>
    </>
  );
};

export const SignUpForm = withFormik<IAuthProps, FormValues>({
  validationSchema: ({t}: IAuthProps) =>
    yup.object().shape({
      email: yup.string().email().required(),
      privacyPolicyAccepted: yup.boolean().oneOf([true], t('please accept to continue')),
      termsOfServiceAccepted: yup.boolean().oneOf([true], t('please accept to continue')),
      preContractAccepted: yup.boolean().when('promoCode', {
        is: 'comercia',
        then: yup.boolean(),
        otherwise: yup.boolean().oneOf([true], t('please accept to continue'))
      }),
      password: yup
        .string()
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          t(
            'password must contain at least 8 characters, one uppercase, one number and one special case character'
          )
        )
        .required(),
      passwordConfirm: yup
        .string()
        .oneOf([yup.ref('password')], t("passwords don't match"))
        .required(t('password confirmation is required'))
    }),
  mapPropsToValues: ({
    email = '',
    password = '',
    amazonCustomerId,
    externalId,
    externalHash,
    referralCode,
    promoCode
  }) => ({
    email,
    password,
    amazonCustomerId,
    externalId,
    externalHash,
    referralCode,
    promoCode,
    privacyPolicyAccepted: false,
    termsOfServiceAccepted: false,
    preContractAccepted: false,
    passwordConfirm: '',
    locale: 'en',
    captcha: ''
  }),
  handleSubmit: async (values, {props, setErrors, setSubmitting}) => {
    const {
      email,
      password,
      amazonCustomerId,
      externalId,
      externalHash,
      referralCode,
      promoCode,
      captcha,
      locale
    } = values;
    const attributes: UserAttributes = {
      email,
      locale,
      'custom:pp_doc': getPolicyUrl(config.privacyPolicyDoc, locale),
      'custom:tos_doc': getPolicyUrl(config.termsOfServiceDoc, locale),
      'custom:pc_doc': getPolicyUrl(config.preContractDoc, locale),
      'custom:pp_accepted_at': values.privacyPolicyAcceptedAt,
      'custom:tos_accepted_at': values.termsOfServiceAcceptedAt,
      'custom:pc_accepted_at': values.preContractAcceptedAt
    };
    const cid = await getClientId();
    if (cid) {
      attributes['custom:cid'] = cid;
    }
    if (amazonCustomerId) {
      attributes['custom:amazon_customer_id'] = amazonCustomerId;
    }
    if (referralCode) {
      attributes['custom:referral_code'] = referralCode;
    }
    if (promoCode) {
      attributes['custom:promo_code'] = promoCode;
    }
    if (externalId) {
      attributes['custom:external_id'] = externalId;
    }
    try {
      const {userConfirmed} = await Auth.signUp({
        username: email,
        password,
        attributes,
        // @ts-ignore
        validationData: {recaptchaToken: captcha, externalHash}
      });
      // sign in user if it is confirmed
      if (userConfirmed) {
        await Auth.signIn(email, password);
        // get current user, CognitoUser returned form Auth.signIn does not have attributes
        const currentUser = await Auth.currentAuthenticatedUser();
        props.setState({authState: AuthState.signedIn, currentUser});
      }
      // else show confirm view
      else {
        props.setState({authState: AuthState.confirmSignUp, email, password});
      }
    } catch (error: any) {
      setErrors({email: normalizeErrorMessage(error.message)});
    } finally {
      setSubmitting(false);
    }
  }
})(C);
