import {List} from 'antd';
import {Logo} from 'components/Auth/Shared';
import {usePartners} from 'lib/partners';
import React from 'react';
import {Trans} from 'react-i18next';
import styled from 'styled-components';

import applePay from 'images/sprite/apple-pay.svg';
import bizum from 'images/sprite/bizum.svg';
import commercetools from 'images/sprite/commercetools.svg';
import googlePay from 'images/sprite/google-pay.svg';
import magento from 'images/sprite/magento.svg';
import mastercard from 'images/sprite/mastercard.svg';
import multibanco from 'images/sprite/multibanco-logo-small.svg';
import prestashop from 'images/sprite/prestashop.svg';
import salesforce from 'images/sprite/salesforce.svg';
import sepa from 'images/sprite/sepa.svg';
import shopify from 'images/sprite/shopify.svg';
import visa from 'images/sprite/visa.svg';
import paypal from 'images/sprite/paypal.svg';
import wix from 'images/sprite/wix.svg';
import woocommerce from 'images/sprite/woocommerce.svg';
import repsol from 'images/sprite/repsol.svg';
import marriott from 'images/sprite/marriott.svg';
import scalpers from 'images/sprite/scalpers.svg';
import mango from 'images/sprite/mango.svg';
import telefonica from 'images/sprite/telefonica.svg';
import monei_pay from 'images/sprite/monei-pay.svg';
import {PlusOutlined} from '@ant-design/icons';

type Props = {};

const Container = styled.div`
  max-width: 500px;
  margin-right: 50px;
`;

const Logos = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  margin: 0 -10px 1em;

  div {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.9;
    transition: 250ms opacity;

    &:hover {
      opacity: 1;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  img {
    margin-bottom: 0;
  }
`;

const PartnerLogo = styled.a`
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlusIcon = styled(PlusOutlined)`
  margin: 0 25px;
  font-size: 25px;
  color: #8a8a8a;
`;

export const SignUpExtra: React.FC<Props> = () => {
  const [partner] = usePartners();
  return (
    <Container>
      <Header>
        <Logo src="https://assets.monei.com/images/logo.svg" />
        {partner && (
          <>
            <PlusIcon />
            <PartnerLogo href={partner.url} target="_blank" title={partner.name}>
              <img src={partner.logo} alt={partner.name} style={partner.logoStyle} />
            </PartnerLogo>
          </>
        )}
      </Header>
      <List itemLayout="vertical" split={false}>
        <List.Item>
          <Trans parent="h3">Accept more payment methods everywhere</Trans>
          {partner ? (
            <Trans parent="p">
              Whether you have an online or physical business, manage your entire payment stack from
              a single platform thanks to MONEI & {{partner: partner.name}}.
            </Trans>
          ) : (
            <Trans parent="p">
              Whether you have an online or physical business, manage your entire payment stack from
              a single platform.
            </Trans>
          )}
          <Logos>
            <div title="Bizum">
              <svg width={70} viewBox={bizum.viewBox}>
                <use xlinkHref={`#${bizum.id}`} />
              </svg>
            </div>
            <div title="Visa">
              <svg width={42} viewBox={visa.viewBox}>
                <use xlinkHref={`#${visa.id}`} />
              </svg>
            </div>
            <div title="MasterCard">
              <svg width={30} viewBox={mastercard.viewBox}>
                <use xlinkHref={`#${mastercard.id}`} />
              </svg>
            </div>
            <div title="Google Pay">
              <svg width={48} viewBox={googlePay.viewBox}>
                <use xlinkHref={`#${googlePay.id}`} />
              </svg>
            </div>
            <div title="Apple Pay">
              <svg width={48} viewBox={applePay.viewBox}>
                <use xlinkHref={`#${applePay.id}`} />
              </svg>
            </div>
            <div title="PayPal">
              <svg width={80} viewBox={paypal.viewBox}>
                <use xlinkHref={`#${paypal.id}`} />
              </svg>
            </div>
            <div title="Multibanco">
              <svg width={25} viewBox={multibanco.viewBox}>
                <use xlinkHref={`#${multibanco.id}`} />
              </svg>
            </div>
            <div>
              <svg width={50} viewBox={sepa.viewBox}>
                <use xlinkHref={`#${sepa.id}`} />
              </svg>
            </div>
          </Logos>
        </List.Item>
        {(!partner || partner.code === 'comercia') && (
          <List.Item>
            <Trans parent="h3">Quick and easy e-commerce integration</Trans>
            <Trans parent="p">
              Plugin with popular e-commerce platforms or use our payments API.
            </Trans>
            <Logos>
              <div title="Shopify">
                <svg width={80} viewBox={shopify.viewBox}>
                  <use xlinkHref={`#${shopify.id}`} />
                </svg>
              </div>
              <div title="WooCommerce">
                <svg width={110} viewBox={woocommerce.viewBox} style={{marginTop: 6}}>
                  <use xlinkHref={`#${woocommerce.id}`} />
                </svg>
              </div>
              <div title="PrestaShop">
                <svg width={110} viewBox={prestashop.viewBox}>
                  <use xlinkHref={`#${prestashop.id}`} />
                </svg>
              </div>
              <div title="Magento">
                <svg width={90} viewBox={magento.viewBox}>
                  <use xlinkHref={`#${magento.id}`} />
                </svg>
              </div>
              <div title="Salesforce">
                <svg width={55} viewBox={salesforce.viewBox}>
                  <use xlinkHref={`#${salesforce.id}`} />
                </svg>
              </div>
              <div title="Commercetools">
                <svg width={130} viewBox={commercetools.viewBox}>
                  <use xlinkHref={`#${commercetools.id}`} />
                </svg>
              </div>
              <div title="Wix">
                <svg width={30} viewBox={wix.viewBox}>
                  <use xlinkHref={`#${wix.id}`} />
                </svg>
              </div>
            </Logos>
          </List.Item>
        )}
        {partner?.code !== 'comercia' && (
          <List.Item>
            <Trans parent="h3">Join Southern Europe’s most successful brands</Trans>
            <Trans parent="p">
              Trusted by thousands of brands, ranging in size from startups to SMEs to large
              enterprises.{' '}
            </Trans>
            <Logos>
              <div title="Telefónica">
                <svg width={115} viewBox={telefonica.viewBox}>
                  <use xlinkHref={`#${telefonica.id}`} />
                </svg>
              </div>
              <div title="Mango">
                <svg width={90} viewBox={mango.viewBox}>
                  <use xlinkHref={`#${mango.id}`} />
                </svg>
              </div>
              <div title="Repsol">
                <svg width={110} viewBox={repsol.viewBox}>
                  <use xlinkHref={`#${repsol.id}`} />
                </svg>
              </div>
              <div title="Marriott">
                <svg width={80} viewBox={marriott.viewBox}>
                  <use xlinkHref={`#${marriott.id}`} />
                </svg>
              </div>
              <div title="Scalpers">
                <svg width={120} viewBox={scalpers.viewBox}>
                  <use xlinkHref={`#${scalpers.id}`} />
                </svg>
              </div>
            </Logos>
          </List.Item>
        )}
        <List.Item>
          <Trans parent="h3">Take in-person payments from your phone</Trans>
          {partner ? (
            <Trans parent="p">
              Use MONEI Pay to accept cards, Bizum, Apple Pay, and Google Pay payments from your
              phone. No website required, thanks to MONEI & {{partner: partner.name}}.
            </Trans>
          ) : (
            <Trans parent="p">
              Use MONEI Pay to accept cards, Bizum, Apple Pay, and Google Pay payments from your
              phone. No website required.
            </Trans>
          )}
          <Logos>
            <div title="MONEI Pay">
              <svg width={120} viewBox={monei_pay.viewBox}>
                <use xlinkHref={`#${monei_pay.id}`} />
              </svg>
            </div>
          </Logos>
        </List.Item>
      </List>
    </Container>
  );
};
