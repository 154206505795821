import {IdcardOutlined, LockOutlined} from '@ant-design/icons/lib';
import {Button} from 'antd';
import {Auth} from 'aws-amplify';
import {FormikProps, withFormik} from 'formik';
import {Form, FormItem, Input} from 'formik-antd';
import {getClientId} from 'lib/gtmEvents';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {AuthState, IAuthProps} from 'types';
import * as yup from 'yup';
import {AnchorLink} from '../AnchorLink';
import {Extra} from './Shared';

interface IFormValues {
  email: string;
  password: string;
}

const C: React.FC<FormikProps<IFormValues> & IAuthProps> = ({isSubmitting, setState, values}) => {
  const {t} = useTranslation();
  const resetPassword = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setState({authState: AuthState.forgotPassword, email: values.email});
  };

  return (
    <Form>
      <FormItem name="email">
        <Input
          autoFocus
          name="email"
          type="email"
          autoComplete="email"
          size="large"
          prefix={<IdcardOutlined />}
          placeholder={t('Email')}
        />
      </FormItem>
      <FormItem name="password">
        <Input.Password
          name="password"
          type="password"
          autoComplete="current-password"
          size="large"
          prefix={<LockOutlined />}
          placeholder={t('Password')}
        />
      </FormItem>
      <Button block size="large" type="primary" loading={isSubmitting} htmlType="submit">
        {t('Log In')}
      </Button>
      <Extra>
        {/*Want to try? <AnchorLink onClick={signInAsDemo}>Sign in as demo user</AnchorLink>*/}
        {/*<br />*/}
        <Trans key="Forgot password hint">
          Forgot your password? <AnchorLink onClick={resetPassword}>Reset password</AnchorLink>
        </Trans>
      </Extra>
    </Form>
  );
};

export const SignInForm = withFormik<IAuthProps, IFormValues>({
  validationSchema: () =>
    yup.object().shape({
      email: yup.string().email().required(),
      password: yup.string().required()
    }),
  mapPropsToValues: ({email = '', password = ''}) => ({email, password}),
  handleSubmit: async ({email, password}, {props: {t, setState}, setErrors, setSubmitting}) => {
    try {
      const cid = await getClientId();
      const user = await Auth.signIn(email, password, {cid: cid!});
      if (user.challengeName) {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          return setState({authState: AuthState.requireNewPassword, currentUser: user, email});
        }
        if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
          return setState({
            email,
            password,
            authState: AuthState.confirmSignIn,
            currentUser: user,
            mfaDestination: user.challengeParam?.CODE_DELIVERY_DESTINATION,
            mfaType: user.challengeName
          });
        }
      }
      // get current user, CognitoUser returned form Auth.signIn does not have attributes
      const currentUser = await Auth.currentAuthenticatedUser();
      setState({authState: AuthState.signedIn, currentUser, email});
    } catch (error: any) {
      // render confirm view if user is not confirmed
      if (error.code === 'UserNotConfirmedException') {
        return setState({authState: AuthState.confirmSignUp, email, password});
      }
      if (error.code === 'NotAuthorizedException') {
        t('Incorrect username or password.', 'Incorrect email or password.');
        t('Unable to login because of security reasons.');
        // i18next-extract-disable-next-line
        return setErrors({password: t(error.message)});
      }
      setErrors({password: error.message});
    } finally {
      setSubmitting(false);
    }
  }
})(C);
