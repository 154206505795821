import styled from 'styled-components';

type Props = {
  gutter?: number|string;
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
  align?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  fullWidth?: boolean;
  wrap?: boolean;
};

export const Stack = styled.div<Props>`
  width: 100%;
  display: flex;
  flex-wrap: ${(props) => props.wrap ? 'wrap' : 'nowrap'};
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: ${(props) => props.align || 'center'};
  ${(props) => props.fullWidth && 'flex: 1'};
  && > * {
    margin-right: ${(props) =>
      ['column', 'column-reverse'].includes(props.direction as string)
        ? 0
        : props.gutter
        ? +props.gutter / 2
        : 5}px !important;
    margin-left: ${(props) =>
      ['column', 'column-reverse'].includes(props.direction as string)
        ? 0
        : props.gutter
        ? +props.gutter / 2
        : 5}px !important;
    margin-top: ${(props) =>
      ['column', 'column-reverse'].includes(props.direction as string)
        ? props.gutter
          ? +props.gutter / 2
          : 5
        : 0}px !important;
    margin-bottom: ${(props) =>
      ['column', 'column-reverse'].includes(props.direction as string)
        ? props.gutter
          ? +props.gutter / 2
          : 5
        : 0}px !important;
  }
  && > *:last-child {
    margin-right: 0 !important;
  }
  && > *:first-child {
    margin-left: 0 !important;
  }
`;
