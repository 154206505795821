import {TFunction} from 'i18next';
import {setLocale} from 'yup';

export const setFormikLocale = (t: TFunction) => {
  setLocale({
    mixed: {
      defined: t('this field is required'),
      required: t('this field is required'),
      default: t('this field is required'),
      oneOf: t('must be one of the following values: {{values}}', {values: '${values}'}),
      notType: ({type}: any) => t('must be a {{type}}', {type})
    },
    string: {
      email: t('must be a valid email'),
      url: t('must be a valid URL'),
      min: t('must be at least {{min}} characters', {min: '${min}'}),
      max: t('must be at most {{max}} characters', {max: '${max}'}),
      length: t('must be exactly {{length}} characters', {length: '${length}'}),
      trim: t('must be a trimmed string'),
      lowercase: t('must be a lowercase string'),
      uppercase: t('must be a upper case string'),
      matches: t('this value is not valid')
    },
    number: {
      min: t('must be greater than or equal to {{min}}', {min: '${min}'}),
      max: t('must be less than or equal to {{max}}', {max: '${max}'}),
      lessThan: t('must be less than {{less}}', {less: '${less}'}),
      moreThan: t('must be greater than {{more}}', {more: '${more}'}),
      positive: t('must be a positive number'),
      negative: t('must be a negative number'),
      integer: t('must be an integer')
    },
    date: {
      min: t('must be later than {{min}}', {min: '${min}'}),
      max: t('must be at earlier than {{max}}', {max: '${max}'})
    },
    array: {
      min: t('must have at least {{min}} items', {min: '${min}'}),
      max: t('must have less than or equal to {{max}} items', {max: '${max}'})
    },
    boolean: {
      // @ts-ignore
      default: t('this field is required')
    }
  });
};
