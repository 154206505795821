import {EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons/lib';
import {Tooltip, Typography} from 'antd';
import {TextProps} from 'antd/es/typography/Text';
import {AnchorLink} from 'components/AnchorLink';
import React, {useState} from 'react';
import styled from 'styled-components';

const {Text} = Typography;

interface IProps extends TextProps {
  text?: string;
  children?: string | null;
}

const SecretString = styled.span`
  letter-spacing: 2.5px;
`;

export const Secret: React.FC<IProps> = ({children, ...props}) => {
  const [isHidden, setHidden] = useState(true);
  const secretString = new Array(children?.length).fill('•');
  return (
    <>
      <Text {...props} style={{marginRight: 10}}>
        {isHidden ? <SecretString>{secretString}</SecretString> : children}{' '}
      </Text>
      <Tooltip title={isHidden ? 'Show' : 'Hide'}>
        <AnchorLink onClick={() => setHidden(!isHidden)}>
          {isHidden ? <EyeOutlined /> : <EyeInvisibleOutlined />}
        </AnchorLink>
      </Tooltip>
    </>
  );
};
