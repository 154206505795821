import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import {defaultLanguage, languages, notSupportedByMoment} from 'languages';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import 'moment/locale/en-gb';
import {initReactI18next} from 'react-i18next';
import {updateZendeskWidgetLanguage} from './lib/zendesk';

const isDev = process.env.NODE_ENV === 'development';

const setMomentLocale = (name: string) => {
  moment.locale(name);
  // @ts-ignore
  momentTZ.updateLocale(name, moment.localeData()._config);
  momentTZ.locale(name);
};

setMomentLocale('en-gb');

i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: defaultLanguage,
    debug: isDev,
    saveMissing: false,
    lowerCaseLng: true,
    load: 'languageOnly',
    cleanCode: true,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'span']
    },
    keySeparator: false,
    nsSeparator: false
  });

i18n.on('languageChanged', async (lng) => {
  if (lng === 'en' || !languages.includes(lng) || notSupportedByMoment.includes(lng)) {
    lng = 'en-gb';
  } else {
    await import(`moment/locale/${lng}`);
  }

  updateZendeskWidgetLanguage(lng);
  setMomentLocale(lng);
});

export default i18n;
