import TagManager from 'react-gtm-module';
import {config} from 'stage.config';

export const getClientId = async () => {
  if (!config.measurementId) {
    return undefined;
  }
  window.dataLayer = window.dataLayer || [];
  function gtag(...args: any[]) {
    window.dataLayer.push(arguments);
  }
  return new Promise<string | undefined>((resolve) => {
    gtag('get', config.measurementId, 'client_id', resolve);
    setTimeout(() => resolve(undefined), 500);
  });
};

export const triggerEvent = (event: string, data?: Record<string, any>) => {
  TagManager.dataLayer({
    dataLayer: {event, ...data}
  });
};

export const signedInEvent = (accountId: string, email: string) => {
  triggerEvent('App.SignIn', {accountId, method: 'email', email});
};

export const signedUpEvent = (accountId: string, email: string) => {
  triggerEvent('App.SignUp', {accountId, method: 'email', email});
};

export const showSignUpEvent = () => {
  triggerEvent('App.ShowSignUp');
};

export const confirmSignUpEvent = (email: string) => {
  triggerEvent('App.ConfirmSignUp', {method: 'email', email});
};

export const showSignInEvent = () => {
  triggerEvent('App.ShowSignIn');
};

export const confirmSignInEvent = (email: string) => {
  triggerEvent('App.ConfirmSignIn', {method: 'email', email});
};

export const kycStartedEvent = (accountId: string, email: string) => {
  triggerEvent('App.KYC.Started', {accountId, email});
};

export const kycSubmittedForReviewEvent = (accountId: string, email: string) => {
  triggerEvent('App.KYC.SubmittedForReview', {accountId, email});
};
